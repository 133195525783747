import React, { useEffect, useMemo, useState } from 'react'
import { useAPI } from '../../../../shared/services/api/API';
import { useLocation, useNavigate } from 'react-router-dom';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { Button, Divider, Grid, IconButton, MenuItem, TextField } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const ProductMasterDetails = () => {
  const { httpGet, httpPut } = useAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const columns = location?.state?.column;
  const [productMasterDetail, setProductMasterDetail] = useState<any>({});
  const rowDetail = location.state?.rowData;
  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };
  // GET with API : Delivery Order Details
  const fetchProductMasterDetail = async (serviceItemId: string) => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.PRODUCT_MASTER_SERVICE_ITEM_DETAIL_GET}/${serviceItemId}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setProductMasterDetail(response?.data);
      } else {
        if (response.data.error) {
          console.error('Error fetching serviceitem: ', response.data.message);
          setProductMasterDetail({});
        }
      }
    });
  };
  
  const categoryData = location?.state?.categoryData;
  const serviceSectionData = location?.state?.serviceSectionData;
  const mediaData = location?.state?.mediaData;
  const userType = location?.state?.userType;
  const busId = location?.state?.busId;
  const { httpPost, handleAlertBar } = useAPI();
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const [values, setValues] = useState<any>(() =>
    columns?.reduce((acc: any, column: { accessorKey: any }) => {
      acc[column.accessorKey ?? ''] = rowDetail[column.accessorKey];
      return acc;
    }, {} as any),
  );
  const [errors, setErrors] = useState<any>({});

  const validate = (name: string, value: any) => {
    const isNumeric = (enteredValue: string) =>
      /^[+-]?\d+(\.\d+)?$/.test(enteredValue);
    let error = '';
    if (
      !value &&
      (name == 'Price' ||
        name == 'Weight' ||
        name == 'Pack Quantity' ||
        name == 'Code' ||
        name === 'Media')
    ) {
      error = `${name} field is required`;
    }
    if (
      value &&
      value.length < 2 &&
      (name == 'Name' || name == 'Description')
    ) {
      error = `The ${name} must be more than 1 character required`;
    }
    if (value && isNumeric(value) && name == 'Name') {
      error = `The ${name} must be in letters`;
    }
    if (
      value &&
      !isNumeric(value) &&
      (name == 'Price' || name == 'Weight' || name === 'Pack Quantity')
    ) {
      error = `${name} field should be in number`;
    }
    return error;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [e.target.name]: e.target.value });

    const error = validate(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleInputBlur = (identifier: any, e: any) => {
    const { name, value } = e.target;
    const error = validate(identifier, value ?? '');
    setErrors({ ...errors, [identifier]: error });
  };

  const handleSubmit = () => {
    const newErrors: any = {};
    columns.forEach((column: any) => {
      if (column.header !== 'ID') {
        const error = validate(column.header, values[column.accessorKey]);
        if (error) {
          newErrors[column.accessorKey] = error;
        }
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      updateBusinessServiceItem(values);
      setValues({});
      onClose();
    }
  };

  const updateBusinessServiceItem = (values: any) => {
    if (!Object.keys(validationErrors).length) {
      const type: any[] = userType.filter(
        (item: any) => item.name === 'Business',
      );
      const payload = {
        name: values?.name || rowDetail?.name,
        description: values?.description || rowDetail?.description,
        price: +values?.price || rowDetail?.price,
        weight: +values?.weight || rowDetail?.weight,
        itemCode: values?.itemCode || rowDetail?.itemCode,
        packQuantity: +values?.packQuantity || rowDetail?.packQuantity,
        categoryId: values?.categoryId || rowDetail?.category?.id,
        mediaItemId: values?.mediaItemId || rowDetail?.mediaItem.id,
        type: type[0].id,
      };

      httpPut(
        WASHUB_CONSTANTS.API_URLS
          .PRODUCT_MASTER_SERVICE_ITEM_UPDATE_DATA_PUT +
          '/' +
          rowDetail.id,
        payload,
      ).then((response) => {
        if (response.status === 200) {
          handleAlertBar(
            'success',
            `Product Master Service Item Updated Successfully`,
          );
          setValues({});
          onClose();
        } else {
          if (response.data.error) {
            console.error('Error create serviceitem: ', response.data.message);
          }
        }
      });
    }
  };

  const onClose = () => {
    navigate('/product-master');
  };
  const backToServiceItemList = () => {
    navigate('..');
  };

  useEffect(() => {
    if (rowDetail?.id) {
        fetchProductMasterDetail(rowDetail?.id);
    }
  }, [rowDetail?.id]);

  return (
    <div>
      <Grid container m={2}>
        <Grid item xs={2} md={1}>
          <IconButton onClick={backToServiceItemList}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} md={10} lg={9}>
          <h4 style={{ marginTop: '5px' }}>
            Product Details: {rowDetail?.name}
          </h4>
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />

      <div style={{ padding: '5px 15px' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
            {columns.map((column: any, index: number) =>
              column.header == 'ID' ? (
                <></>
              ) : column.header == 'Category' ? (
                <Grid item xs={12} md={6} lg={6}>
                  {' '}
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    label={column.header}
                    name={column.accessorKey}
                    select
                    required
                    onChange={handleChange}
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                    defaultValue={rowDetail?.category?.id}
                  >
                    {categoryData.map((option: any) => (
                      <MenuItem key={option} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) :
              column.header == 'Item Image' ? (
                <Grid item xs={12} md={6} lg={6}>
                  {' '}
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    label={column.header}
                    name={column.accessorKey}
                    select
                    defaultValue={rowDetail?.mediaItem?.id}
                    onChange={handleChange}
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  >
                    {mediaData.map((option: any) => (
                      <MenuItem key={option} value={option?.id}>
                        <img src={option?.url} width={40} height={20} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : column.header == 'Name' || column.header == 'Description' ? (
                <Grid item xs={12} md={6} lg={6}>
                  {' '}
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    id={column.header}
                    label={column.header}
                    name={column.accessorKey}
                    defaultValue={rowDetail[column.accessorKey]}
                    onChange={handleChange}
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    id={column.header}
                    label={column.header}
                    name={column.accessorKey}
                    defaultValue={rowDetail[column.accessorKey]}
                    onChange={handleChange}
                    required
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  />
                </Grid>
              ),
            )}
          </Grid>
        </form>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          className="m-3"
          color="primary"
          sx={{ color: 'white' }}
          onClick={handleSubmit}
          variant="contained"
        >
          Update Product Master
        </Button>
      </div>
    </div>
  );
};
