import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  ICartData,
  LaundryCartContext,
} from '../../../orders/context/service.context';
import { Categories, WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { Assets } from '../../../../assets/laundry';
import { useAPI } from '../../../../shared/services/api/API';
import { AxiosResponse } from 'axios';
import {
  IServiceItem,
  TServiceItems,
} from '../../../../shared/models/ServiceItems.model';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { UserDataContext } from '../../context/service.context';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SuccessModal from '../../../../shared/SuccessModal';
import { ClothesSelectionList } from '../clothes-selection-list/ClothesSelectionList';
import { CustomButton } from '../../../../orderCenter/modules/CustomButton';

export const ClothesSeletion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const clientInfo = location?.state?.clientInfo;
  const orderDetails = location?.state?.orderDetails;
  const { data, setData } = useContext(LaundryCartContext);
  const { httpGet, httpPost } = useAPI();
  const [clothesFilterList, setClothesFilterList] = useState([]);
  const [clothesDetaillist, setClothesDetaillist] = useState([]);
  const [selectService, setSelectService] = useState(
    clothesFilterList ? clothesFilterList[0] : '',
  );
  const [clothesList, setClothesList] = useState<any>([]);
  const [serviceItemOrder, setServiceItemOrder] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setQuantity] = useState(0);
  const [discount, setDiscount] = useState(0);
  const { serviceItem, serviceItemTax, dispatch, userInfo } =
    useContext(UserDataContext);
  const [itemTax, setItemTax] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [open, setOpen] = React.useState(false);
  const [vatPercentage, setVatPercentage] = useState<number>(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [filterClothes, setFilterClothes] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState('');
  const key = localStorage.getItem('key');
  const [searchQueries, setSearchQueries] = useState<any>({});
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const handleExpanded = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean)  => {
    setExpanded(isExpanded ? panel : false)
  }

  const isBusinessOrder = location.pathname.includes(
    'business-clothes-selection',
  );
  const isBusinessStoreOrder = location.pathname.includes('businessStoreOrder');
  useEffect(() => {
    if (clothesDetaillist?.length === 0) {
      if (isBusinessStoreOrder) {
        fetchBusinessStoreDepartmentData();
      } else {
        fetchServiceItem();
      }
    } else {
      handleFilterService(clothesFilterList ? clothesFilterList[0] : '');
    }
  }, [clothesDetaillist]);

  useEffect(() => {
    if (departmentId) {
      setSearchQueries({});
      fetchServiceItem(departmentId);
    }
  }, [departmentId]);

  useEffect(() => {
    const isRefreshed = !sessionStorage.getItem('isRefreshed');
    if (isRefreshed) {
      if (location.state) {
        navigate(`/businessStoreOrder/clothes-selection?key=${key}`, {
          replace: true,
          state: null,
        });
      }
    }
    sessionStorage.setItem('isRefreshed', 'true');
  }, [location, navigate]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('isRefreshed');
    };
  }, []);

  useMemo(() => {
    if (serviceItem) {
      const updatedItem: any = {};
      let total = 0;
      let quantity = 0;
      serviceItem?.map((item: any) => {
        updatedItem[item.id] = item.quantity;
        setSelectedItems(updatedItem);
        total += item.price * item.quantity;
        quantity += item.quantity;
      });
      setServiceItemOrder(serviceItem);
      setQuantity(totalQuantity + quantity);
      setTotalPrice(totalPrice + total);
    }
  }, []);
  const fetchServiceItem = async (departmentId?: string) => {
    const key = localStorage.getItem('key');
    const retailUrl = `${WASHUB_CONSTANTS.API_URLS.RETAILSTORE_SERVICE_ITEM_DATA_GET}/${key}`;
    const businessStoreUrl = `${WASHUB_CONSTANTS.API_URLS.BUSINESS_CUSTOMER_ORDER_OPTI_bUS_STORE_ITEMS_GET}/${key}/${departmentId}`;
    const businessUrl = `${WASHUB_CONSTANTS.API_URLS.BUSINESS_CUSTOMER_ORDER_OPTI_ITEMS_GET}/${key}`;
    await httpGet(
      isBusinessOrder
        ? businessUrl
        : isBusinessStoreOrder
          ? businessStoreUrl
          : retailUrl,
    ).then((response) => {
      if (response.status === 200) {
        setClothesDetaillist((): any => response?.data?.retailServiceItem);
        setClothesFilterList((): any => response?.data?.serviceCategories);
        setItemTax(response?.data?.taxPercentage);
      } else {
        if (response.data.error) {
          console.error('Error fetching Business: ', response.data.message);
        }
      }
    });
  };
  const fetchBusinessStoreDepartmentData = async () => {
    const key = localStorage.getItem('key');
    await httpGet(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_DEPARTMENT_DATA_GET}/${key}`,
    ).then((response) => {
      if (response.status === 200) {
        const sortBusinessDeptArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setDepartmentList(sortBusinessDeptArray);
        if (location.state?.orderDetails?.departmentId) {
          setDepartmentId(location.state?.orderDetails?.departmentId);
        }
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Store Department: ',
            response.data.message,
          );
        }
      }
    });
  };

  const handleBack = () => {
    if (isBusinessOrder) {
      navigate(`/businessCustomerOrder?key=${key}`);
    } else if (isBusinessStoreOrder) {
      navigate(`/businessStoreOrder?key=${key}`);
    }
    else {
      navigate(`/retailCustomerOrder?key=${key}`);
    }
  };

  const handleBag = () => {
    dispatch({ type: 'SELECT_ITEM', payload: serviceItemOrder });
    dispatch({ type: 'SELECT_ITEM_TAX', payload: itemTax });
    if (isBusinessOrder) {
      navigate('/business-checkout');
    } else {
      navigate('/checkout');
    }
  };

  const handleFilterService = async (clothList: any) => {
    setSelectService(clothList);
    const list: any = clothesDetaillist?.find(
      (item: any) => clothList === item.categoryName,
    );
    await setClothesList(list?.serviceItemSection);
  };

  const removeItem = (item: any) => {
    const index = serviceItemOrder.findIndex(
      (itemDetail: any) => itemDetail.id === item.id,
    );
    if (index !== -1) {
      const updatedItemDetail = [...serviceItemOrder];
      if (updatedItemDetail[index].quantity === 1) {
        updatedItemDetail.splice(index, 1);
      } else {
        updatedItemDetail[index].quantity--;
      }
      setServiceItemOrder(updatedItemDetail);
    }
    const updatedItems: any = { ...selectedItems };
    if (updatedItems[item?.id]) {
      updatedItems[item?.id]--;
      if (updatedItems[item?.id] === 0) {
        delete updatedItems[item?.id];
      }
      setSelectedItems(updatedItems);
    }
  };

  const addItem = (item: any) => {
    const index = serviceItemOrder.findIndex(
      (itemDetail: any) => itemDetail.id === item.id,
    );
    if (index !== -1) {
      const updatedItemDetail = [...serviceItemOrder];
      updatedItemDetail[index].quantity++;
      setServiceItemOrder(updatedItemDetail);
    } else {
      setServiceItemOrder([...serviceItemOrder, { ...item, quantity: 1 }]);
    }
    const updatedItems = { ...selectedItems };
    updatedItems[item?.id] = updatedItems[item?.id]
      ? updatedItems[item?.id] + 1
      : 1;
    setSelectedItems(updatedItems);
  };
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchInput(event.target.value);
  };
  const handleSearchInputChangeAccordion = (
    sectionName: any,
    event: any,
  ) => {
    setSearchQueries({
      ...searchQueries,
      [sectionName]: event?.target?.value.toLowerCase()
    });
  };
  const filteredClothesList = clothesList?.map((section: any)=> ({...section,optiServiceItem : section.optiServiceItem.filter((item: any) => item.name?.toLowerCase().includes(searchQueries[section?.serviceItemSectionName] ? searchQueries[section?.serviceItemSectionName] : '')) }))
  const filteredOptiServiceItem = clothesList?.map((section: any)=> ({...section,optiServiceItem : section.optiServiceItem.filter((item: any) => item.name?.toLowerCase().includes(searchInput?.toLowerCase())) }))
  const buid = localStorage.getItem('buid');

  useEffect(() => {
    const vat = (totalPrice * +itemTax) / 100;
    setVatPercentage(vat);
    setGrandTotal(totalPrice + vatPercentage);
  }, [vatPercentage, totalPrice]);

  const submitHandler = () => {
    dispatch({ type: 'SELECT_ITEM', payload: serviceItemOrder });
    dispatch({ type: 'SELECT_ITEM_TAX', payload: itemTax });
    navigate('/service-type', {
      state: {
        departmentId: departmentId,
        totalPrice: totalPrice,
        serviceItemOrder: serviceItemOrder,
        totalQuantity: totalQuantity,
        isFrom: "BusinessStoreOrder"
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const itemChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: any,
  ) => {
    const { value } = event.target;
    let quantity = parseInt(value, 10);

    if (isNaN(quantity) || quantity < 0) {
      quantity = 0;
    }

    // Update selectedItems state
    setSelectedItems((prevItems: any) => ({
      ...prevItems,
      [item.id]: quantity,
    }));

    setServiceItemOrder((prevOrder: any) => {
      const itemIndex = prevOrder.findIndex(
        (orderItem: any) => orderItem.id === item.id,
      );
      if (itemIndex !== -1) {
        const updatedOrder = [...prevOrder];
        updatedOrder[itemIndex].quantity = quantity;
        return updatedOrder;
      }
      return [...prevOrder, { ...item, quantity }];
    });
  };
  useEffect(() => {
    const totalQuantity = serviceItemOrder.reduce(
      (acc: any, item: any) => acc + item.quantity,
      0,
    );
    setQuantity(totalQuantity);
    const updatedItems = serviceItemOrder.map((item: any) => {
      return { ...item, totalPrice: item.price * item.quantity };
    });
    const overallTotalPrice = updatedItems.reduce(
      (acc: any, item: any) => acc + item.totalPrice,
      0,
    );
    setTotalPrice(overallTotalPrice);
  }, [serviceItemOrder]);

  return (
    <div style={{ top: '-10px', position: 'relative' }}>
      <Grid
        container
        display={'flex'}
        justifyContent={'center'}
        style={{
          backgroundColor: '#045464',
          padding: '10px 15px 15px',
          borderRadius: 'none',
        }}
      >
        <Grid item md={0.5} lg={0.5}>
        <Link
              component="button"
              title="back to user detail"
              onClick={handleBack}
            >
              <KeyboardArrowLeftIcon
                fontSize="large"
                style={{ color: '#ffffff' }}
              />
            </Link>
        </Grid>
        <Grid
          item
          xs={10}
          sm={11}
          md={11}
          lg={11}
          display={'flex'}
          justifyContent={'center'}
        >
          <Typography
            variant="h5"
            className="mb-0"
            style={{ color: '#ffffff', padding: '3px' }}
          >
            Select Laundry Items
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        marginTop={2}
        padding={'0px 10px'}
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Grid sx={{ display: 'flex' }}>
          {clothesFilterList?.map((item: string, index: number) => (
            <Grid item key={item}>
              <Chip
                color="primary"
                size="medium"
                id="buttons"
                variant={selectService === item ? 'filled' : 'outlined'}
                className="m-2"
                label={item}
                onClick={() => handleFilterService(item)}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item>
          {filteredOptiServiceItem?.length === 1 && (
            <TextField
              variant="outlined"
              placeholder="Search..."
              id="inputSearchItems"
              value={searchInput}
              onChange={handleSearchInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& input': {
                    padding: '8px 14px', // Adjust the padding to fit the new height
                  },
                },
              }}
            />
          )}
        </Grid>
        <Grid item lg={4} md={3} sm={3} xs={12}>
          {departmentList.length > 0 ? (
            <TextField
              fullWidth
              key="department"
              label="Select Department"
              name="department"
              select
              onChange={(e) => {
                setDepartmentId(e.target.value);
              }}
              value={departmentId}
            >
              {departmentList.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
        </Grid>
      </Grid>
      <div style={{ maxHeight: '32vh', overflow: 'auto', padding: '5px 20px' }}>
        {filteredOptiServiceItem?.length > 0 ? (
          filteredOptiServiceItem?.length === 1 ? (
            filteredOptiServiceItem.map((data: any, index: any) => (
              <ClothesSelectionList
                filteredClothesList={data?.optiServiceItem}
                removeItem={removeItem}
                key={index}
                addItem={addItem}
                itemChangeHandler={itemChangeHandler}
                selectedItems={selectedItems}
                useFor={isBusinessStoreOrder ? "businessStoreOrder" : ''}
              />
            ))
          ) : (
            filteredClothesList.map((data: any, index: any) => (
              <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleExpanded(`panel${index}`)}
              key={`panel${index}`}
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #045464',
                  padding: '0.5rem 0.5rem',
                  margin: '0.5rem 0rem',
                }}
              >
                <AccordionSummary
                  sx={{
                    padding: '0px 5px',
                    textAlign: 'center',
                    margin: '0px',
                    '& .css-13tsglk-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded':
                      {
                        minHeight: '0px',
                        '& .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded':
                          {
                            margin: '0px',
                          },
                      },
                  }}
                  style={{ minHeight: '35px', height: '40px' }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Grid
                    container
                    display={'flex'}
                    direction={'row'}
                    justifyContent={'space-between'}
                  >
                    <Grid item>
                      <DialogContentText
                        sx={{
                          fontSize: '17px',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        {data?.serviceItemSectionName}
                      </DialogContentText>
                    </Grid>
                    <Grid item>
                        <TextField
                            key={data?.id}
                            variant="outlined"
                            placeholder="Search..."
                            id="inputSearchItem"
                            // value={item?.searchInput}
                            onChange={(e) =>handleSearchInputChangeAccordion(data?.serviceItemSectionName,e)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& input': {
                                  padding: '8px 14px', // Adjust the padding to fit the new height
                                },
                              },
                            }}
                          />
                      </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0px',
                  }}
                >
                  <ClothesSelectionList
                    filteredClothesList={data?.optiServiceItem}
                    removeItem={removeItem}
                    addItem={addItem}
                    itemChangeHandler={itemChangeHandler}
                    selectedItems={selectedItems}
                    useFor={isBusinessStoreOrder ? "businessStoreOrder" : ''}
                  />
                </AccordionDetails>
              </Accordion>
            ))
          )
        ) : (
          <></>
        )}
      </div>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: '10px 20px',
          left: '50%',
          transform: ' translateX(-50%)',
        }}
        elevation={3}
      >
        {!isBusinessStoreOrder ? (<Accordion
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            padding: '1rem',
          }}
        >
          <AccordionSummary
            sx={{ padding: '0px', textAlign: 'center' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Grid
              container
              display={'flex'}
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Grid item>
                <DialogContentText sx={{ fontSize: '15px', color: 'black' }}>
                  Orders
                </DialogContentText>
              </Grid>
              <Grid item>
                <DialogContentText sx={{ fontSize: '15px', color: 'black' }}>
                  AED {Number(totalPrice + discount).toFixed(2)}
                </DialogContentText>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: 'flex', flexDirection: 'column', padding: '0px' }}
          >
            <Card
              sx={{ minWidth: 275, maxHeight: '150px', overflow: 'auto' }}
              elevation={2}
            >
              <CardContent>
                {serviceItemOrder?.length > 0 &&
                  serviceItemOrder.map((item: any, index: number) => (
                    <Grid
                      key={index}
                      container
                      display={'flex'}
                      direction={'row'}
                      justifyContent={'space-between'}
                    >
                      <Grid item>
                        <DialogContentText
                          sx={{ fontSize: '14px', color: 'black' }}
                        >
                          {item?.quantity} X {item?.name}
                        </DialogContentText>
                      </Grid>
                      <Grid item>
                        <DialogContentText
                          sx={{ fontSize: '14px', color: 'black' }}
                        >
                          AED {(item?.price * item?.quantity).toFixed(2)}
                        </DialogContentText>
                      </Grid>
                    </Grid>
                  ))}
                <Grid
                  container
                  display={'flex'}
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '14px', color: 'black' }}
                    >
                      Subtotal ({totalQuantity} items){' '}
                    </DialogContentText>
                  </Grid>
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '14px', color: 'black' }}
                    >
                      AED {totalPrice.toFixed(2)}
                    </DialogContentText>
                  </Grid>
                </Grid>
                <Grid
                  container
                  display={'flex'}
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '14px', color: 'black' }}
                    >
                      Vat {itemTax} %
                    </DialogContentText>
                  </Grid>
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '14px', color: 'black' }}
                    >
                      AED {vatPercentage.toFixed(2)}
                    </DialogContentText>
                  </Grid>
                </Grid>
                <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />
                <Grid
                  container
                  display={'flex'}
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <Grid item>
                    <Typography variant="h5">Total</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">
                      AED{' '}
                      {Number(totalPrice + discount + vatPercentage).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </AccordionDetails>
        </Accordion>) : null}
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display={'flex'}
            justifyContent={'center'}
          >
            {isBusinessStoreOrder ? (
              <CustomButton
                fullWidth
                color="primary"
                onClick={submitHandler}
                variant="contained"
                disabled={totalPrice === 0}
                label={'Select Service Type'}
              />
            ) : (
              <CustomButton
                fullWidth
                color="primary"
                onClick={handleBag}
                variant="contained"
                disabled={totalPrice === 0}
                label={'Deliver Options >>'}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
