import React, { useEffect, useMemo, useState } from 'react'
import { useAPI } from '../../../../shared/services/api/API';
import { useLocation, useNavigate } from 'react-router-dom';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { Button, Divider, Grid, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const PriceMasterDetail = () => {
  const { httpGet, httpPut } = useAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const columns = location?.state?.column;
  const [priceMasterDetail, setPriceMasterDetail] = useState<any>({});
  const rowDetail = location.state?.rowData;
  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };
  // GET with API : Delivery Order Details
  const fetchPriceMasterDetail = async (serviceItemId: string) => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_SERVICE_ITEM_DETAIL_GET}/${serviceItemId}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setPriceMasterDetail(response?.data);
      } else {
        if (response.data.error) {
          console.error('Error fetching serviceitem: ', response.data.message);
          setPriceMasterDetail({});
        }
      }
    });
  };
  
  const categoryData = location?.state?.categoryData;
  const serviceSectionData = location?.state?.serviceSectionData;
  const mediaData = location?.state?.mediaData;
  const userType = location?.state?.userType;
  const busId = location?.state?.busId;
  const { httpPost, handleAlertBar } = useAPI();
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const [values, setValues] = useState<any>(() =>
    columns?.reduce((acc: any, column: { accessorKey: any }) => {
      acc[column.accessorKey ?? ''] = rowDetail[column.accessorKey];
      return acc;
    }, {} as any),
  );
  const [errors, setErrors] = useState<any>({});

  const validate = (name: string, value: any) => {
    const isNumeric = (enteredValue: string) =>
      /^[+-]?\d+(\.\d+)?$/.test(enteredValue);
    let error = '';
    if (
      !value &&
      (name == 'Price' ||
        name == 'Weight' ||
        name == 'Pack Quantity' ||
        name == 'Code' ||
        name === 'Media')
    ) {
      error = `${name} field is required`;
    }
    if (
      value &&
      value.length < 2 &&
      (name == 'Name' || name == 'Description')
    ) {
      error = `The ${name} must be more than 1 character required`;
    }
    if (value && isNumeric(value) && name == 'Name') {
      error = `The ${name} must be in letters`;
    }
    if (
      value &&
      !isNumeric(value) &&
      (name == 'Price' || name == 'Weight' || name === 'Pack Quantity')
    ) {
      error = `${name} field should be in number`;
    }
    return error;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [e.target.name]: e.target.value });

    const error = validate(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleInputBlur = (identifier: any, e: any) => {
    const { name, value } = e.target;
    const error = validate(identifier, value ?? '');
    setErrors({ ...errors, [identifier]: error });
  };

  const handleSubmit = () => {
    const newErrors: any = {};
    columns.forEach((column: any) => {
      if (column.header !== 'ID') {
        const error = validate(column.header, values[column.accessorKey]);
        if (error) {
          newErrors[column.accessorKey] = error;
        }
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      updateBusinessServiceItem(values);
      setValues({});
      backToServiceItemList();
    }
  };

  const updateBusinessServiceItem = (values: any) => {
    if (!Object.keys(validationErrors).length) {
        const payload = {
            storeId: rowDetail?.businessStore?.id,
            serviceItemId: rowDetail?.serviceItem?.id,
            customerDescription:
            values?.customerDescription ||
            rowDetail?.customerDescription,
            price: +values?.price || values?.price,
            weight: +values?.weight || values?.weight,
            packQuantity: +values?.packQuantity || values?.packQuantity,
          };

      httpPut(
        WASHUB_CONSTANTS.API_URLS
          .PRICE_MASTER_SERVICE_ITEM_UPDATE_DATA_PUT +
          '/' +
          rowDetail.id,
        payload,
      ).then((response) => {
        if (response.status === 200) {
          handleAlertBar(
            'success',
            `Price Master Service Item Updated Successfully`,
          );
          setValues({});
          backToServiceItemList();
        } else {
          if (response.data.error) {
            console.error('Error create serviceitem: ', response.data.message);
          }
        }
      });
    }
  };

  const backToServiceItemList = () => {
    navigate('..', { state: { busStoreId: rowDetail?.businessStore?.id } });
  };

  useEffect(() => {
    if (rowDetail?.id) {
        fetchPriceMasterDetail(rowDetail?.id);
    }
  }, [rowDetail?.id]);

  return (
    <div>
      <Grid container m={1}>
        <Grid item xs={2} md={1} lg={0.5}>
          <IconButton onClick={backToServiceItemList}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} md={11} lg={11.5}>
          <h4 style={{ marginTop: '5px' }}>
            Price Details: {rowDetail?.customerDescription}
          </h4>
        </Grid>
      </Grid>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <TextField
            size="small"
            style={{ width: '30%' }}
            key="businessStoreId"
            label="Select Business Store"
            name="productMasterId"
            defaultValue={rowDetail?.businessStore?.name}
            disabled
            className='mb-2 ms-3'
        />
      </Stack>
      <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />

      <div style={{ padding: '5px 15px' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
            {columns.map((column: any, index: number) =>
              column.header == 'ID' ? (
                <></>
              ) : (
                <Grid item xs={12} md={6} lg={6} key={column.accessorKey}>
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    id={column.header}
                    label={column.header}
                    name={column.accessorKey}
                    defaultValue={rowDetail[column.accessorKey]}
                    onChange={handleChange}
                    required
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  />
                </Grid>
              ),
            )}
          </Grid>
        </form>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          className="m-3"
          color="primary"
          sx={{ color: 'white' }}
          onClick={handleSubmit}
          variant="contained"
        >
          Update Price Master
        </Button>
      </div>
    </div>
  );
};
