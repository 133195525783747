import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../shared/services/api/API';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import {
  MaterialReactTable,
  MaterialReactTableProps,
  MRT_ColumnDef,
  MRT_EditActionButtons,
} from 'material-react-table';
import { PriceMasterItemDetails } from '../../models/ProductMaster';
import { Delete, Edit } from '@mui/icons-material';
import { AlertDialog } from '../../../../shared/components/alert-dialog/AlertDialog';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';

export const ProductPriceMasterDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const rowDetail = location?.state?.rowData;
  const { httpGet, httpPut, httpPost, httpDelete, handleAlertBar } = useAPI();
  const [productMasterId, setProductMasterId] = useState('');
  const [tableData, setTableData] = useState<PriceMasterItemDetails[]>([]);
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const [deletePriceMasterData, setDeletePriceMasterData] = useState<any>([]);
  const [businessStoreId, setBusinessStoreId] = useState('');
  const [businessStoreData, setBusinessStoreData] = useState<any>([]);
  const [createBusinessStoreData, setCreateBusinessStoreData] = useState<any>([]);
  const [existingBusinessStoreId, setExistingBusinessStoreId] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openMapDeptDialog, setOpenMapDeptDialog] = useState(false);
  const [associateDeptPayload, setAssociateDeptPayload] = useState<any>([]);
  const [departmentId, setDepartmentId] = useState<string[]>([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [errors, setErrors] = useState<any>({});

  const validate = (name: string, value: any) => {
    const isNumeric = (enteredValue: string) =>
      /^[+-]?\d+(\.\d+)?$/.test(enteredValue);
    let error = '';
    if (
      !value &&
      (name == 'Customer Description' ||
        name == 'Price' ||
        name == 'Weight' ||
        name === 'Pack Quantity')
    ) {
      error = `${name} field is required`;
    }
    if (value && value.length < 2 && name == 'Customer Description') {
      error = `The ${name} must be more than 1 character required`;
    }
    if (value && isNumeric(value) && name == 'Customer Description') {
      error = `The ${name} must be in letters`;
    }
    if (
      value &&
      !isNumeric(value) &&
      (name == 'Price' || name == 'Weight' || name === 'Pack Quantity')
    ) {
      error = `${name} field should be in number`;
    }
    return error;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [e.target.name]: e.target.value });

    const error = validate(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleInputBlur = (identifier: any, e: any) => {
    const { name, value } = e.target;
    const error = validate(identifier, value ?? '');
    setErrors({ ...errors, [identifier]: error });
  };

  const handleChangeDepartment = (
    event: SelectChangeEvent<typeof departmentId>,
  ) => {
    const {
      target: { value },
    } = event;
    console.log(value)
    setDepartmentId(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSubmit = () => {
    const newErrors: any = {};
    columns.forEach((column: any) => {
      if (column.header !== 'ID') {
        const error = validate(column.header, values[column.accessorKey]);
        if (error) {
          newErrors[column.accessorKey] = error;
        }
      }
    });
    if (businessStoreId === '') {
      newErrors['businessStoreId'] = 'Business Store field is required';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      createPriceMaster(values);
      setValues({});
    }
  };

  const queryParams = {
    page: 0,
    size: 10000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };

  // GET with API
  const fetchPriceMaster = async (serviceItemId: string) => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_SERVICE_ITEM_FOR_PRODUCTMASTER_DATA_GET}/${serviceItemId}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setTableData((): any => response?.data);
        const businessStoreExist = response?.data?.map((item: any)=> item?.businessStore?.id)
        setExistingBusinessStoreId(businessStoreExist);
      } else {
        if (response.data.error) {
          console.error('Error fetching serviceitem: ', response.data.message);
          setTableData([]);
        }
      }
    });
  };
  // PUT with API
  const handleSaveRowEdits: MaterialReactTableProps<PriceMasterItemDetails>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        const payload = {
          storeId: businessStoreId || row?.original?.businessStore?.id,
          serviceItemId: row?.original?.serviceItem?.id,
          customerDescription:
            valuesDetail?.customerDescription ||
            row.original?.customerDescription,
          price: +valuesDetail?.price || values?.price,
          weight: +valuesDetail?.weight || values?.weight,
          packQuantity: +valuesDetail?.packQuantity || values?.packQuantity,
        };

        httpPut(
          WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_SERVICE_ITEM_UPDATE_DATA_PUT +
            '/' +
            row.original.id,
          payload,
        ).then((response) => {
          if (response.status === 200) {
            fetchPriceMaster(productMasterId);
            handleAlertBar('success', `Price Master Item updated Successfully`);
            setValuesDetail({});
            setBusinessStoreId('');
            exitEditingMode(); //required to exit editing mode and close modal
          } else {
            if (response.data.error) {
              handleAlertBar('error', response.data.message);
              console.error(
                'Error Updating serviceitem: ',
                response.data.message,
              );
            }
          }
        });
      }
    };
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const columns = useMemo<MRT_ColumnDef<PriceMasterItemDetails>[]>(
    () => [
      {
        accessorKey: 'customerDescription',
        header: 'Customer Description',
        size: 50,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        size: 50,
      },
      {
        accessorKey: 'weight',
        header: 'Weight',
        size: 50,
      },
      {
        accessorKey: 'packQuantity',
        header: 'Pack Quantity',
        size: 50,
      },
      {
        accessorKey: 'businessStore.name',
        header: 'Business Store',
        size: 50,
      },
    ],
    [],
  );

  const [valuesDetail, setValuesDetail] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const handleDeletePriceMaster = (row: any) => {
    setDeletePriceMasterData(row);
    setIsAlertDialog(true);
  };
  const handleAlertDialogClose = () => {
    setIsAlertDialog(false);
  };

  const deletePriceMaster = () => {
    httpDelete(
      `${WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_SERVICE_ITEM_REMOVE_DATA_DELETE}/${deletePriceMasterData?.id}`,
    )
      .then((response) => {
        if (response?.status === 200) {
          fetchPriceMaster(productMasterId);
          handleAlertDialogClose();
          handleAlertBar('success', `Price Master deleted sucessfully`);
        }
      })
      .catch((error: any) => {
        handleAlertBar('error', error);
      });
  };

  const addItem = () => {
    const newBusinessStore = businessStoreData?.filter((item: any)=> !existingBusinessStoreId.includes(item?.id));
    setCreateBusinessStoreData(newBusinessStore);
    setOpenDialog(true);
    // fetchPriceMaster(productMasterId);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const createPriceMaster = (values: any) => {
    if (!Object.keys(validationErrors).length) {
      const requestBody = {
        storeId: businessStoreId,
        serviceItemId: productMasterId,
        price: +values?.price,
        weight: +values?.weight,
        packQuantity: +values?.packQuantity,
        customerDescription: values?.customerDescription,
      };

      httpPost(
        WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_SERVICE_ITEM_CREATE_DATA_POST,
        requestBody,
      ).then((response) => {
        if (response.status === 200) {
          handleAlertBar(
            'success',
            `New Price Master Item Created Successfully`,
          );
          setValues({});
          setBusinessStoreId('');
          setOpenDialog(false);
          fetchPriceMaster(productMasterId);
        } else {
          if (response.data.error) {
            console.error(
              'Error Creating serviceitem: ',
              response.data.message,
            );
          }
          handleAlertBar('error', response.data.message);
        }
      });
    }
  };

    // GET with API : BUSINESSSTORE
    const fetchBusinessStore = async () => {
      httpGet(
        `${WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_BUSINESS_STORE_DATA_GET}`,
        queryParams,
      ).then((response) => {
        if (response.status === 200) {
          const sortBusinessStoreArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          setBusinessStoreData(sortBusinessStoreArray);
        } else {
          if (response.data.error) {
            console.error(
              'Error fetching BusinessStore: ',
              response.data.message,
            );
          }
        }
      });
    };

      // GET with API : BUSINESSSTORE
    const fetchDepartmentStore = async (busStoreId: string) => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_BUSINESS_DEPARTMENT_DATA_GET}/${busStoreId}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        const sortBusinessDeptArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setDepartmentList(sortBusinessDeptArray);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };
  
    useEffect(() => {
        fetchBusinessStore();
    }, []);

  useEffect(() => {
    if (rowDetail?.id) {
      setProductMasterId(rowDetail?.id);
      fetchPriceMaster(rowDetail?.id);
    }
  }, [rowDetail?.id]);

  const backToServiceItemList = () => {
    navigate('..');
  };

  const handleAssociateDepartment = (row: any) => {
    setBusinessStoreId(row?.businessStore?.id);
    fetchDepartmentStore(row?.businessStore?.id);
    setAssociateDeptPayload(row);
    setOpenMapDeptDialog(true);
    getAllDeptMapPriceMaster(row);

  };
  const handleDialogClose = () => {
    setOpenMapDeptDialog(false);
    setDepartmentId([]);
  };

  const getAllDeptMapPriceMaster = (row: any) => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_MAPPER_DEPT_DATA_GET}/${row?.id}`,
    ).then((response) => {
      if (response.status === 200) {
        const mappedDept = response?.data?.map((res: any) => res?.businessDepartment?.name);
        setDepartmentId(mappedDept);
      } else {
        if (response.data.error) {
          handleAlertBar('error', response.data.message);
          console.error('Error Updating serviceitem: ', response.data.message);
        }
      }
    });
  };

  const associateDeptWithPriceMaster = () => {
    const deptIdArray = departmentList
      ?.filter((item: any) => departmentId?.includes(item?.name))
      ?.map((e: any) => e?.id);
    const payload = {
      storeId: associateDeptPayload?.businessStore?.id,
      serviceItemId: associateDeptPayload?.serviceItem?.id,
      priceMasterId: associateDeptPayload?.id,
      departmentId: deptIdArray,
    };

    httpPost(
      WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_ASSOCIATE_DEPT_DATA_POST,
      payload,
    ).then((response) => {
      if (response.status === 200) {
        fetchPriceMaster(productMasterId);
        handleAlertBar(
          'success',
          `Departments associated with this price master Successfully`,
        );
        setDepartmentId([]);
        setOpenMapDeptDialog(false);
      } else {
        if (response.data.error) {
          handleAlertBar('error', response.data.message);
          console.error('Error Updating serviceitem: ', response.data.message);
        }
      }
    });
  };

  return (
    <div style={{ padding: '5px 15px', height: 'calc(100vh - 12vh)' }}>
        <Grid container justifyContent={'space-between'} className="mb-2 mt-1">
          <Grid item>
          <Grid container>
            <Grid item>
            <Typography
              color={'primary'}
              sx={{ textAlign: 'start', cursor: 'pointer' }}
              variant="h5"
              className="mt-1"
              onClick={backToServiceItemList}
            >
              Product Master
            </Typography>
            </Grid>
            <Grid item alignContent={'center'}>
              <NavigateNextIcon />
            </Grid>
            <Grid item>
              <Typography
                sx={{ textAlign: 'center' }}
                variant="h5"
                className="mt-1"
              >
                Price Master List
              </Typography>
            </Grid>
          </Grid>
          </Grid>
          <Grid item>
          <Button
            color="primary"
            sx={{ color: 'white' }}
            onClick={() => addItem()}
            variant="contained"
          >
            Add Price Master
          </Button>
        </Grid>
      </Grid>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <TextField
            size="small"
            style={{ width: '30%' }}
            key="productMasterId"
            label="Select Product Master"
            name="productMasterId"
            defaultValue={rowDetail?.name}
            disabled
            className='mb-2'
        />
      </Stack>
          <div style={{ marginTop: '2px' }}>
          <MaterialReactTable
              muiTableContainerProps={{
                sx: {
                  height: '56vh',
                },
              }}
              defaultColumn={{
                maxSize: 150,
                minSize: 10,
                size: 50
              }}
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 50,
                },
              }}
              columns={columns}
              data={tableData}
              enableFullScreenToggle={false}
              positionActionsColumn="last"
              enableStickyHeader
              enableColumnOrdering
              enableEditing
              enableHiding
              editDisplayMode="modal"
              onEditingRowSave={handleSaveRowEdits}
              onEditingRowCancel={handleCancelRowEdits}
              renderEditRowDialogContent={({
                table,
                row,
                internalEditComponents,
              }) => (
                <Dialog fullWidth open={true}>
                  <DialogTitle
                    sx={{ backgroundColor: '#83CBEB', color: '#ffff' }}
                    variant="h5"
                    textAlign={'center'}
                    className="p-3 mb-3"
                  >
                    Update Price Master
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={3}>
                      {columns.map((column) => (
                        <>
                          {column.accessorKey === 'customerDescription' && (
                            <Grid
                              key={column.accessorKey}
                              item
                              xs={12}
                              md={6}
                              lg={6}
                            >
                              <TextField
                                fullWidth
                                key={column.accessorKey}
                                variant="standard"
                                label={column.header}
                                name={column.accessorKey}
                                defaultValue={row.original.customerDescription}
                                onChange={(e) =>
                                  setValuesDetail({
                                    ...valuesDetail,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                          )}
                          {column.accessorKey === 'price' && (
                            <Grid
                              key={column.accessorKey}
                              item
                              xs={12}
                              md={6}
                              lg={6}
                            >
                              <TextField
                                fullWidth
                                key={column.accessorKey}
                                variant="standard"
                                label={column.header}
                                name={column.accessorKey}
                                defaultValue={row.original.price}
                                onChange={(e) =>
                                  setValuesDetail({
                                    ...valuesDetail,
                                    [e.target.name]: +e.target.value,
                                  })
                                }
                              />
                            </Grid>
                          )}
                          {column.accessorKey === 'weight' && (
                            <Grid
                              key={column.accessorKey}
                              item
                              xs={12}
                              md={6}
                              lg={6}
                            >
                              <TextField
                                fullWidth
                                key={column.accessorKey}
                                variant="standard"
                                label={column.header}
                                name={column.accessorKey}
                                defaultValue={row.original.weight}
                                onChange={(e) =>
                                  setValuesDetail({
                                    ...valuesDetail,
                                    [e.target.name]: +e.target.value,
                                  })
                                }
                              />
                            </Grid>
                          )}
                          {column.accessorKey === 'packQuantity' && (
                            <Grid
                              key={column.accessorKey}
                              item
                              xs={12}
                              md={6}
                              lg={6}
                            >
                              <TextField
                                fullWidth
                                key={column.accessorKey}
                                variant="standard"
                                label={column.header}
                                name={column.accessorKey}
                                defaultValue={row.original.packQuantity}
                                onChange={(e) =>
                                  setValuesDetail({
                                    ...valuesDetail,
                                    [e.target.name]: +e.target.value,
                                  })
                                }
                              />
                            </Grid>
                          )}
                          {column.accessorKey === 'businessStore.name' && (
                            <Grid
                              key={column.accessorKey}
                              item
                              xs={12}
                              md={6}
                              lg={6}
                            >
                            <TextField
                              fullWidth
                              key={column.accessorKey}
                              label={column.header}
                              variant="standard"
                              name={column.accessorKey}
                              select
                              onChange={(e) => {
                                setBusinessStoreId(e.target.value);
                              }}
                              defaultValue={row.original.businessStore?.id}
                              error={!!errors['businessStoreId']}
                              helperText={errors['businessStoreId']}
                            >
                              {businessStoreData.map((option: any) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                  </TextField>
                            </Grid>
                          )}
                        </>
                      ))}
                      
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                  </DialogActions>
                </Dialog>
              )}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                  <Tooltip arrow placement="right" title="Associate Dept">
                    <IconButton
                      onClick={() => handleAssociateDepartment(row?.original)}
                    >
                      <img src="../images/associate.png" width={22} height={20} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="right" title="Edit">
                    <IconButton
                      onClick={() => {
                        table.setEditingRow(row);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="right" title="Delete">
                    <IconButton
                      onClick={() => handleDeletePriceMaster(row?.original)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            />
          </div>
      <Dialog fullWidth
        maxWidth='md'  open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle textAlign="center">Add Price Master</DialogTitle>
      <DialogContent>
      <Grid container spacing={3}>
            {columns?.map((column: any, index: number) =>
              column.header == 'ID' ? (
                <></>
              ) : column.header == 'Business Store' ? (
                <Grid item xs={12} md={6} lg={6}>
                  {' '}
                  <TextField
                fullWidth
                key="businessStore"
                label="Select Business Store*"
                name="businessStore"
                select
                onChange={(e) => {
                  setBusinessStoreId(e.target.value);
                }}
                value={businessStoreId}
                error={!!errors['businessStoreId']}
                helperText={errors['businessStoreId']}
              >
                {createBusinessStoreData?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
                </Grid>
              ) : (
                <Grid item xs={12} md={6} lg={6} key={column.accessorKey}>
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    id={column.header}
                    label={column.header}
                    name={column.accessorKey}
                    value={values[column.accessorKey]}
                    onChange={handleChange}
                    required
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  />
                </Grid>
              ),
            )}
          </Grid>
      </DialogContent>
      <DialogActions className="dialog-btn">
      <Button color='error' variant="contained" sx={{color: 'white'}} onClick={handleCloseDialog}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          sx={{color: 'white'}}
          variant="contained"
        >
          Add Item
        </Button>
      </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openMapDeptDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle textAlign="center">Associate Department</DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: '100%', marginTop: "5px"}}>
            <InputLabel id="demo-multiple-name-label">
              Select Department*
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              multiple
              value={departmentId || ''}
              onChange={handleChangeDepartment}
              input={<OutlinedInput label="Select Department" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {departmentList.map((option: any) => (
                <MenuItem key={option.name} value={option.name}>
                  <Checkbox checked={departmentId?.includes(option.name)} />
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className="dialog-btn">
          <Button
            color="error"
            variant="contained"
            sx={{ color: 'white' }}
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            onClick={associateDeptWithPriceMaster}
            sx={{ color: 'white' }}
            variant="contained"
          >
            Mapped Department
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={isAlertDialog}
        title={'Delete Price Master'}
        description={'Are you sure you want to delete this price master ?'}
        submitBtnText={'Delete'}
        cancelBtnText={'Cancel'}
        handleSubmit={deletePriceMaster}
        handleClose={handleAlertDialogClose}
      ></AlertDialog>
    </div>
  );
};
