import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Row,
} from 'material-react-table';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ReportBusinessStoreOrder } from '../../makeData';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Card,
  CardActions,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BusinessContext } from '../../../../shared/BusinessContext';
export const BusinessSales = () => {
  const { axiosInstance, httpGet, httpPost, handleAlertBar } = useAPI();
  const [businessId, setBusinessId] = useState('');
  const [businessStoreId, setBusinessStoreId] = useState('');
  const [businessStoreData, setBusinessStoreData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const type = localStorage.getItem('userType');
  const { businessList } = useContext(BusinessContext);
  const [tableData, setTableData] = useState<ReportBusinessStoreOrder[]>([]);
  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };

  const columns = useMemo<MRT_ColumnDef<ReportBusinessStoreOrder>[]>(
    () => [
      {
        accessorKey: 'serviceItem.name',
        header: 'Item Name',
        enableEditing: false,
        size: 10,
      },
      {
        accessorKey: 'serviceItem.serviceCategoryName',
        header: 'Item Category',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        size: 10,
      },
      {
        accessorKey: 'itemCount',
        header: 'Total Count',
        enableEditing: false,
        enableHiding: true,
        size: 10,
      },
      {
        accessorKey: 'orderItemStatus',
        header: 'Status',
        size: 20,
      },
    ],
    [],
  );

  // GET with API : BUSINESSSTORE
  const fetchBusinessStore = async (id: string) => {
    let url = '';
    if (type === 'ClientAdmin') {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_GET}`;
    } else if (type === 'Business') {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_FOR_BUSINESS_GET}`;
    }
    httpGet(url + '/' + id, queryParams).then((response) => {
      if (response.status === 200) {
        const sortBusinessStoreArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setBusinessStoreData(sortBusinessStoreArray);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  // POST with API  : get all business order items for Business Store
  const getAllBusinessOrderItemForBusinessStore = async (
    businessStoreId: string,
    startDate?: any,
    endDate?: any,
  ) => {
    const payload = {
      startDate: startDate?.format('YYYY-MM-DD'),
      endDate: endDate?.format('YYYY-MM-DD'),
      businessId: businessId,
      businessStoreId: businessStoreId,
    };
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.REPORT_GET_ALL_BUSINESS_ORDER_ITEMS_FOR_BUSINESS_STORE}`,
      payload,
    ).then((response: any) => {
      if (response.status === 200) {
        setTableData(response.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Order for Invoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  // POST with API : Generate Business Sales Summury Raport
  const generateBusinessSalesSummaryReport = async () => {
    const payload = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      businessId: businessId,
      businessStoreId: businessStoreId,
    };
    const selectedBusinessStoreDetail: any = businessStoreData.filter((storeDetail: any) => storeDetail.id === businessStoreId);
    const businessStoreName = selectedBusinessStoreDetail[0].name;
    axiosInstance
      .post(
        WASHUB_CONSTANTS.API_URLS
          .REPORT_GENERATE_BUSINESS_SALES_FOR_BUSINESS_STORE,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem('jwtToken') || null,
          },
          responseType: 'blob',
        },
      )
      .then((response: any) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], {type: 'application/xlsx'});
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          const fileName = `Sales-Summary-${businessStoreName} ${payload.startDate} to ${payload.endDate}.xlsx`;
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();

          a.remove();
          window.URL.revokeObjectURL(url);
        } else {
          if (response.data.error) {
            console.error(
              'Error fetching BusinessStoreInvoices: ',
              response.data.message,
            );
          }
        }
      });
  };

  const [startDate, setStartDate] = useState(dayjs().subtract(5, 'days'));
  const [endDate, setEndDate] = useState(dayjs());
  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    if (businessId && businessStoreId) {
      getAllBusinessOrderItemForBusinessStore(businessStoreId, date, endDate);
    }
  };
  const handleEndDateChange = (date: any) => {
    setEndDate(date);
    if (businessId && businessStoreId) {
      getAllBusinessOrderItemForBusinessStore(businessStoreId, startDate, date);
    }
  };

  // Business Store
  useEffect(() => {
    if (businessId) {
      fetchBusinessStore(businessId);
    }
  }, [businessId]);

  useEffect(() => {
    if (businessStoreId) {
      getAllBusinessOrderItemForBusinessStore(
        businessStoreId,
        startDate,
        endDate,
      );
    }
  }, [businessStoreId]);
  return (
    <div>
      <Grid container m={2}>
        <Grid item xs={12} md={4} mr={2}>
          <TextField
            key="business"
            fullWidth
            label="Select Business"
            name="business"
            select
            onChange={(e) => {
              setBusinessId(e.target.value);
            }}
            value={businessId}
          >
            {businessList?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          {businessId ? (
            <TextField
              fullWidth
              key="businessStore"
              label="Select Business Store"
              name="businessStore"
              select
              onChange={(e) => {
                setBusinessStoreId(e.target.value);
              }}
              value={businessStoreId}
            >
              {businessStoreData.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
      <hr></hr>
      <Card sx={{ margin: '5px 15px' }} elevation={2}>
        <CardActions>
          <Grid container className="m-2" spacing={2}>
            <Grid item xs={12} sm={12} md lg>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Start date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="startDate"
                  value={dayjs(startDate)}
                  onChange={handleStartDateChange}
                  sx={{ width: '50%' }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md lg>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'End date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="endDate"
                  value={dayjs(endDate)}
                  minDate={dayjs(startDate)}
                  onChange={handleEndDateChange}
                  sx={{ width: '50%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
                <Button
                  variant="contained"
                  onClick={generateBusinessSalesSummaryReport}
                >
                  Download the Report
                </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <div style={{ padding: '5px 15px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          muiTableContainerProps={{
            sx: {
              height: '42vh',
            },
          }}
          columns={columns}
          data={tableData}
          enableFullScreenToggle={false}
          enableStickyHeader
          enableColumnActions={false}
          enableColumnFilters={false}
          enableFilters={true}
          enableSorting={false}
          enableHiding={false}
          enableDensityToggle={false}
        />
      </div>
    </div>
  );
};
