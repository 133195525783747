import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  MaterialReactTable,
  MRT_EditActionButtons,
  type MaterialReactTableProps,
  type MRT_Cell,
  type MRT_ColumnDef,
  type MRT_Row,
} from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { BusinessStoreDetails } from './makeData';
import { useAPI } from '../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { useNavigate } from 'react-router-dom';
import { BusinessContext } from '../../../shared/BusinessContext';

export const BusinessStore = () => {
  const navigate = useNavigate();
  const { httpGet, httpPost, httpPut, handleAlertBar } = useAPI();
  const [tableData, setTableData] = useState<BusinessStoreDetails[]>([]);
  const [businessStoreId, setBusinessStoreId] = useState('');
  const [costId, setCostId] = useState('');
  const [processingId, setProcessingId] = useState('');
  const [createStoreProcessingOpenDialog, setCreateStoreProcessingOpenDialog] =
    useState(false);
  const [updatedProcessingOpenDialog, setUpdatedProcessingOpenDialog] =
    useState(false);
  const [createStoreCostOpenDialog, setCreateStoreCostOpenDialog] =
    useState(false);
  const [updatedCostOpenDialog, setUpdatedCostOpenDialog] = useState(false);
  const [costList, setCostList] = useState<any>([]);
  const [processingList, setProcessingList] = useState<any>([]);
  const [costListDisplay, setCostListDisplay] = useState<any>([]);
  const [processingListDisplay, setProcessingListDisplay] = useState<any>([]);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const queryParams = {
    page: 0,
    size: 10000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };

  // GET with API : BUSINESSSTORE
  const fetchBusinessStore = async () => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_ADMIN_BUSINESS_DATA_GET,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        const sortBusinessStoreArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setTableData(sortBusinessStoreArray);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  // Business Store
  useEffect(() => {
    fetchBusinessStore();
    fetchProcessingCenter();
    fetchCostCenter();
  }, []);

  // PUT with API

  const handleSaveRowEdits: MaterialReactTableProps<BusinessStoreDetails>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        const payload = {
          name: valuesDetail?.name,
          address: row.original?.address,
          address2: row.original.address2,
          city: row.original.city,
          state: row.original.state,
          country: row.original.country,
          zipCode: row.original.country,
          mail: row.original.mail,
          phone: row.original?.phone,
          contactName: row.original.contactPhone,
          contactPhone: row.original.contactPhone,
          businessId: row.original.business.id,
        };
        httpPut(
          WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_PUT +
            '/' +
            row?.original?.id,
          payload,
        ).then((response) => {
          console.log('jwtToken :', localStorage.getItem('jwtToken'));
          console.log('RESPONSE :', response);
          if (response.status === 200) {
            fetchBusinessStore();
            setValuesDetail({});
            handleAlertBar('success', `Business Store updated Successfully`);
            exitEditingMode(); //required to exit editing mode and close modal
          } else {
            if (response.data.error) {
              console.error(
                'Error Updating BusinessStore: ',
                response.data.message,
              );
            }
          }
        });
      }
    };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const columns = useMemo<MRT_ColumnDef<BusinessStoreDetails>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 140,
      },
      {
        accessorKey: 'costCenter.name',
        header: 'Cost Center',
        enableEditing: false,
      },
      {
        accessorKey: 'processingCenter.name',
        header: 'Processing Center',
        enableEditing: false,
      },
    ],
    [processingListDisplay, costListDisplay],
  );

  const createNewBusinessStore = () => {
    const column = [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 140,
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 140,
      },
      {
        accessorKey: 'address2',
        header: 'Address-2',
        size: 140,
      },
      {
        accessorKey: 'zipCode',
        header: 'Zipcode',
      },
      {
        accessorKey: 'mail',
        header: 'Email',
      },
      {
        accessorKey: 'state',
        header: 'State',
      },
      {
        accessorKey: 'country',
        header: 'Country',
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
      },
      {
        accessorKey: 'contactName',
        header: 'Contact Name',
      },
      {
        accessorKey: 'contactPhone',
        header: 'Contact Phone',
      },
    ];
    navigate('/create-business-store', {
      state: { column },
    });
  };

  const fetchCostCenter = async () => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.COST_CENTER_DATA_GET}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        const values = response.data.map((item: any) => item.name);
        setCostListDisplay(values);
        setCostList(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  const fetchProcessingCenter = async () => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.PROCESSING_CENTER_DATA_GET}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        const values = response.data.map((item: any) => item.name);
        setProcessingListDisplay(values);
        setProcessingList(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  const handleCreateStoreCost = (row: any) => {
    setBusinessStoreId(row?.id);
    setCostId(row?.costCenter?.id);
    if (row?.costCenter?.id) {
      setUpdatedCostOpenDialog(true);
    } else {
      setCreateStoreCostOpenDialog(true);
    }
  };
  const handleCreateStoreCostDialogClose = () => {
    setCreateStoreCostOpenDialog(false);
    setUpdatedCostOpenDialog(false);
    setCostId('');
  };

  const handleCreateStoreProcessing = (row: any) => {
    setBusinessStoreId(row?.id);
    setProcessingId(row?.processingCenter?.id);
    if (row?.processingCenter?.id) {
      setUpdatedProcessingOpenDialog(true);
    } else {
      setCreateStoreProcessingOpenDialog(true);
    }
  };
  const handleCreateStoreProcessingDialogClose = () => {
    setCreateStoreProcessingOpenDialog(false);
    setUpdatedProcessingOpenDialog(false);
    setProcessingId('');
  };

  const createCostForBusinessStore = () => {
    const payload = {
      storeId: +businessStoreId,
      costCenterId: +costId,
    };

    httpPost(
      WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_COST_CENTER_DATA_POST,
      payload,
    ).then((response) => {
      if (response.status === 200) {
        fetchBusinessStore();
        handleAlertBar(
          'success',
          `Cost center for business store created Successfully`,
        );
        setCostId('');
        setCreateStoreCostOpenDialog(false);
      } else {
        if (response.data.error) {
          handleAlertBar('error', response.data.message);
          console.error('Error Updating serviceitem: ', response.data.message);
        }
      }
    });
  };

  const updateCostForBusinessStore = () => {
    const payload = {
      storeId: +businessStoreId,
      costCenterId: +costId,
    };

    httpPut(
      WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_COST_CENTER_DATA_PUT +
        '/' +
        costId,
      payload,
    ).then((response) => {
      if (response.status === 200) {
        fetchBusinessStore();
        handleAlertBar(
          'success',
          `Cost center for business store updated Successfully`,
        );
        setCostId('');
        setUpdatedCostOpenDialog(false);
      } else {
        if (response.data.error) {
          handleAlertBar('error', response.data.message);
          console.error('Error Updating serviceitem: ', response.data.message);
        }
      }
    });
  };

  const createProcessingForBusinessStore = () => {
    const payload = {
      storeId: +businessStoreId,
      processCenterId: +processingId,
    };

    httpPost(
      WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_PROCESSING_CENTER_DATA_POST,
      payload,
    ).then((response) => {
      if (response.status === 200) {
        fetchBusinessStore();
        handleAlertBar(
          'success',
          `Processing center for business store created Successfully`,
        );
        setProcessingId('');
        setCreateStoreProcessingOpenDialog(false);
      } else {
        if (response.data.error) {
          handleAlertBar('error', response.data.message);
          console.error('Error Updating serviceitem: ', response.data.message);
        }
      }
    });
  };

  const updateProcessingForBusinessStore = () => {
    const payload = {
      storeId: +businessStoreId,
      processCenterId: +processingId,
    };

    httpPut(
      WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_PROCESSING_CENTER_DATA_PUT +
        '/' +
        processingId,
      payload,
    ).then((response) => {
      if (response.status === 200) {
        fetchBusinessStore();
        handleAlertBar(
          'success',
          `Processing center for business store updated Successfully`,
        );
        setProcessingId('');
        setUpdatedProcessingOpenDialog(false);
      } else {
        if (response.data.error) {
          handleAlertBar('error', response.data.message);
          console.error('Error Updating serviceitem: ', response.data.message);
        }
      }
    });
  };

  const [valuesDetail, setValuesDetail] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  return (
    <div style={{ width: '100%', height: '89vh', overflow: 'hidden' }}>
      <Grid container justifyContent={'space-between'}>
        <Grid item className="mb-1 mt-1 ms-3">
          <Typography
            color={'primary'}
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-0"
          >
            Business Store
          </Typography>
        </Grid>
        <Grid item className="mb-1 mt-1 me-3">
          <Button
            color="primary"
            sx={{ color: 'white' }}
            onClick={createNewBusinessStore}
            variant="contained"
          >
            Create Business Store
          </Button>
        </Grid>
      </Grid>

      <div style={{ padding: '5px 15px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          muiTableContainerProps={{
            sx: {
              height: '64vh',
            },
          }}
          columns={columns}
          data={tableData}
          enableFullScreenToggle={false}
          enableStickyHeader
          enableColumnOrdering
          enableEditing
          enableHiding
          editDisplayMode="modal"
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          renderEditRowDialogContent={({
            table,
            row,
            internalEditComponents,
          }) => (
            <Dialog fullWidth open={true}>
              <DialogTitle
                sx={{ backgroundColor: '#83CBEB', color: '#ffff' }}
                variant="h5"
                textAlign={'center'}
                className="p-3 mb-3"
              >
                Update Business Store
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={3}>
                  {columns.map((column) => (
                    <>
                      {column.accessorKey === 'name' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={12}
                          lg={12}
                        >
                          <TextField
                            fullWidth
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.name}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              </DialogContent>
              <DialogActions>
                <MRT_EditActionButtons variant="text" table={table} row={row} />
              </DialogActions>
            </Dialog>
          )}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="left" title="Cost Center">
                <IconButton
                  onClick={() => handleCreateStoreCost(row?.original)}
                >
                  <img src="images/costCenter.png" width={22} height={20} />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="left" title="Processing Center">
                <IconButton
                  onClick={() => handleCreateStoreProcessing(row?.original)}
                >
                  <img
                    src="images/processingCenter.png"
                    width={22}
                    height={20}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      </div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={createStoreCostOpenDialog || updatedCostOpenDialog}
        onClose={handleCreateStoreCostDialogClose}
      >
        <DialogTitle textAlign="center">Cost Center Mapping</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            key="costCenter"
            style={{ width: '100%', marginTop: '5px' }}
            label="Select Cost Center"
            name="costCenter"
            select
            onChange={(e) => {
              setCostId(e.target.value);
            }}
            value={costId}
          >
            {costList?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions className="dialog-btn">
          <Button
            color="error"
            variant="contained"
            sx={{ color: 'white' }}
            onClick={handleCreateStoreCostDialogClose}
          >
            Cancel
          </Button>
          <Button
            onClick={
              updatedCostOpenDialog
                ? updateCostForBusinessStore
                : createCostForBusinessStore
            }
            sx={{ color: 'white' }}
            variant="contained"
          >
            {updatedCostOpenDialog ? 'Updated Mapped Cost' : 'Mapped Cost'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={createStoreProcessingOpenDialog || updatedProcessingOpenDialog}
        onClose={handleCreateStoreProcessingDialogClose}
      >
        <DialogTitle textAlign="center">Processing Center Mapping</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            key="processingCenter"
            style={{ width: '100%', marginTop: '5px' }}
            label="Select Processing Center"
            name="processingCenter"
            select
            onChange={(e) => {
              setProcessingId(e.target.value);
            }}
            value={processingId}
          >
            {processingList?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions className="dialog-btn">
          <Button
            color="error"
            variant="contained"
            sx={{ color: 'white' }}
            onClick={handleCreateStoreProcessingDialogClose}
          >
            Cancel
          </Button>
          <Button
            onClick={
              updatedProcessingOpenDialog
                ? updateProcessingForBusinessStore
                : createProcessingForBusinessStore
            }
            sx={{ color: 'white' }}
            variant="contained"
          >
            {updatedProcessingOpenDialog
              ? 'Updated Mapped Processing'
              : 'Mapped Processing'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BusinessStore;
