import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  Button,
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { data } from './makeData';
import { useAPI } from '../../../shared/services/api/API';
import { OrderType, WASHUB_CONSTANTS } from '../../../shared/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { BusinessOrderList } from './business-store-order-list/BusinessOrderList';
import { BusinessContext } from '../../../shared/BusinessContext';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export type BusinessStoreOrderDeatils = {
  id: string;
  name: string;
  description: string;
  price: string;
  categoryId: string;
  mediaItemId: string;
  orderId: string;
  orderDate: string;
  orderStatus: string;
};

export const BusinessStoreOrder = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const BusinessStoreOrder = location?.state?.order;
  const BusinessDetail = location?.state?.orderDetails;
  const { httpPost, httpGet, handleAlertBar  } = useAPI();

  const [businessId, setBusinessId] = useState('');
  const { businessList } = useContext(BusinessContext);

  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState('');

  const [businessStoreId, setBusinessStoreId] = useState('');
  const [businessStoreData, setBusinessStoreData] = useState([]);

  const [tableData, setTableData] = useState<BusinessStoreOrderDeatils[]>(
    () => data,
  );
  const [IsLoading, setIsLoading] = useState(false);
  const type = localStorage.getItem('userType');
  const [ filterStatus, setFilterStatus] = useState<any>('ALL');
  const [filterStatusList, setFilterStatusList] = useState<any>([
    'REWASH',
    "READYTODELIVER_PARTIAL",
    "READYTODELIVER",
    "DELIVERED",
    "DELIVERED_PARTIAL",
    "CANCELLED"
  ])
  const [ orderId, setOrderId] = useState("");

  // Business Store
  useEffect(() => {
    if (businessId) {
      fetchBusinessStore(businessId);
      setOrderId("");
    }
  }, [businessId]);

  // edit order
  useEffect(() => {
    if (BusinessStoreOrder?.business?.id || BusinessDetail?.businessStore?.business?.id) {
      setBusinessId(BusinessStoreOrder?.business?.id || BusinessDetail?.businessStore?.business?.id);
    }
    if (BusinessStoreOrder?.businessStore?.id || BusinessDetail?.businessStore?.id) {
      setBusinessStoreId(BusinessStoreOrder?.businessStore?.id || BusinessDetail?.businessStore?.id);
    }
    if (BusinessStoreOrder?.department?.id || BusinessDetail?.businessDepartment?.id) {
      setDepartmentId(BusinessStoreOrder?.department?.id || BusinessDetail?.businessDepartment?.id);
    }
  }, [BusinessStoreOrder]);

  useEffect(() => {
    if (businessStoreId) {
      fetchBusinessStoreDepartmentData(businessStoreId);
      fetchBusinessStoreOrder(businessStoreId);
      setOrderId("");
    }
  }, [businessStoreId]);

  const queryParams = {
    page: 0,
    size: 10000,
    sortColumn: "modifiedAt"
  };

  useEffect(() => {
    if (departmentId) {
      fetchBusinessDepartmentOrder(departmentId);
      setOrderId("");
    }
  }, [departmentId]);

  // GET with API : BUSINESSSTORE
  const fetchBusinessStore = async (id: string) => {
    let url = '';
    if( type === 'ClientAdmin') {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_GET}`;

    } else if ( type === 'Business') {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_FOR_BUSINESS_GET}`;
    }
    httpGet(url + '/' + id, queryParams).then(
      (response) => {
        if (response.status === 200) {
          const sortBusinessStoreArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          setBusinessStoreData(sortBusinessStoreArray);
        } else {
          if (response.data.error) {
            console.error(
              'Error fetching BusinessStore: ',
              response.data.message,
            );
          }
        }
      },
    );
  };

  // GET with API : Business Store Order
  const fetchBusinessStoreOrder = async (id: string) => {
    setBusinessStoreId(id);
    setIsLoading(true);
    httpGet(
      WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_DATA_GET +
        '/' +
        businessId +
        '/' +
        id,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStoreOrder: ',
            response.data.message,
          );
          setTableData([]);
          setIsLoading(false);
        }
      }
    });
  };

  // GET with API : Business Department Order
  const fetchBusinessDepartmentOrder = async (id: string) => {
    setIsLoading(true);
    httpGet(
      WASHUB_CONSTANTS.API_URLS.BUSINESS_ORDER_DATA_GET +
      '/' +
      businessId +
      '/' +
      businessStoreId +
      '/' +
      id
    ).then((response) => {
      if (response.status === 200) {
        setIsLoading(false);
        setTableData(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Order: ',
            response.data.message,
          );
          setTableData([]);
          setIsLoading(false);
        }
      }
    });
  };

  const fetchBusinessStoreDepartmentData = async (businessStoreId: string) => {
    await httpGet(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DEPARTMENT_DATA_GET}/${businessStoreId}`,queryParams
    ).then((response) => {
      if (response.status === 200) {
        const sortBusinessDeptArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setDepartmentList(sortBusinessDeptArray);
        if (location.state?.orderDetails?.departmentId) {
          setDepartmentId(location.state?.orderDetails?.departmentId);
        }
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Store Department: ',
            response.data.message,
          );
        }
      }
    });
  };

  const updateFilterStatus = (value: string) => {
    setFilterStatus(value);
    if(value === 'ALL') {
      if(departmentId) {
        fetchBusinessDepartmentOrder(departmentId)
      } else {
        fetchBusinessStoreOrder(businessStoreId)
      }
    } else {
      const payload = {
        status: value,
        businessId: businessId,
        businessStoreId: businessStoreId,
        businessDeptId: departmentId
      }
      httpPost(
        `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_STATUS_FILTER_POST}`,
        payload,
      ).then((response) => {
          if (response?.status === 200) {
              setTableData(response?.data)
            }
          })
        .catch((error: any) => {
          handleAlertBar("error", error);
        });
    }
  }

  const refetch = () => {
    setOrderId("");
    if(departmentId) {
      fetchBusinessDepartmentOrder(departmentId);
    } else if(businessStoreId){
      fetchBusinessStoreOrder(businessStoreId);
    }
  }

  const fetchBusinessOrderById = useMemo(
    () =>
      debounce((orderId: string, businessId: string, businessStoreId: string, departmentId: string) => {
        const payload = {
          businessId: businessId,
          businessStoreId: businessStoreId,
          businessDeptId: departmentId,
          orderId: orderId
        }
        if(businessId) {
          httpPost(
            WASHUB_CONSTANTS.API_URLS.SEARCH_BUSINESS_ORDER_BY_ORDERID_POST, payload
          ).then((response) => {
            if (response.status === 200) {
              setTableData(response.data);
            } else {
              if (response.data.error) {
                handleAlertBar(
                  'error',
                  "Please Select business"
                );
              }
            }
          });
        } else {
          handleAlertBar(
            'error',
            "Please Select business then search order Id"
          );
        }
      }, 700),
    []
  );

  return (<div style={{width: '100%', overflow: 'hidden' }}>
      <Grid container >
          <Grid item className='mb-1 mt-1 ms-3'>
            <Typography sx={{textAlign: 'center'}} variant='h5' className='mt-0'>Business Orders</Typography>
          </Grid>
      </Grid>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          padding: '2px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
          <TextField
            size="small"
            key="business"
            style={{ width: '30%'}}
            label="Select Business"
            name="business"
            select
            onChange={(e) => {
              setBusinessId(e.target.value);
            }}
            value={businessId}
          >
            {businessList?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
            <TextField
              size="small"
              key="businessStore"
              style={{ width: '30%'}}
              label="Select Business Store"
              name="businessStore"
              select
              onChange={(e) => {
                setBusinessStoreId(e.target.value);
              }}
              value={businessStoreId}
              disabled={businessId === ''}
            >
              {businessStoreData.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              size="small"
              style={{ width: '30%' }}
              key="department"
              label="Select Department"
              name="department"
              select
              onChange={(e) => {
                setDepartmentId(e.target.value);
              }}
              value={departmentId}
              disabled={businessStoreId === ''}
            >
              {departmentList.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
        <TextField
          size="small"
          style={{ width: '30%' }}
          key="filterStatus"
          label="Select Status"
          name="filterStatus"
          select
          onChange={(e) => {
            updateFilterStatus(e.target.value);
          }}
          value={filterStatus}
        >
            <MenuItem key={"ALL"} value={"ALL"}>
              {"ALL"}
            </MenuItem>
          {filterStatusList.map((option: any) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          padding: '8px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <div style={{width: '76%'}}>

        <TextField
            size="small"
            key="orderId"
            className='me-3'
            style={{ width: '31.5%',}}
            label="Select Order Id"
            name="orderId"
            onChange={(e) => {
              setOrderId(e.target.value);
              fetchBusinessOrderById(e.target.value, businessId, businessStoreId, departmentId);
            }}
            variant='outlined'
            value={orderId}
            InputProps={{
              endAdornment: (
                orderId && (
                  <InputAdornment position="end">
                    <Link component="button" title={"Clear"}>
                      <CloseIcon
                        fontSize="small"
                        onClick={() => {setOrderId(""); refetch()}}
                      />
                    </Link>
                  </InputAdornment>
                )
              ),
              startAdornment:(
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              )
            }}
          >
          </TextField>

          <Button
          color="primary"
          sx={{color: 'white'}}
          onClick={() => {
            navigate('orders', {
              state: {
                edit: false,
                orderType: OrderType.Business,
                order: {
                  business: { id: businessId },
                  businessStore: { id: businessStoreId },
                  department: {id: departmentId}
                },
              },
            });
          }}
          variant="contained"
        >
          Create Business Order
        </Button>
        </div>
        <Tooltip arrow title="Refresh Table Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <BusinessOrderList
        setTableData={setTableData}
        fetchBusinessDepartmentOrder={fetchBusinessDepartmentOrder}
        fetchBusinessStoreOrder={fetchBusinessStoreOrder}
        fetchBusinessOrderById={fetchBusinessOrderById}
        tableData={tableData}
        OrderType={OrderType}
        businessId={businessId}
        businessStoreId={businessStoreId}
        departmentId={departmentId}
        orderId={orderId}
        IsLoading = {IsLoading}
      />
    </div>)
};
