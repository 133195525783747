import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const CreateProductMaster = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columns = location?.state?.column;
  const categoryData = location?.state?.categoryData;
  const serviceSectionData = location?.state?.serviceSectionData;
  const mediaData = location?.state?.mediaData;
  const userType = location?.state?.userType;
  const busId = location?.state?.busId;
  const { httpPost, handleAlertBar } = useAPI();
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const [values, setValues] = useState<any>(() =>
    columns?.reduce((acc: any, column: { accessorKey: any }) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );
  const [errors, setErrors] = useState<any>({});

  const validate = (name: string, value: any) => {
    const isNumeric = (enteredValue: string) =>
      /^[+-]?\d+(\.\d+)?$/.test(enteredValue);
    let error = '';
    if (
      !value &&
      (name == 'Price' ||
        name == 'Name' ||
        name == 'Weight' ||
        name == 'Pack Quantity' ||
        name == 'Code' ||
        name == 'Category' ||
        name === 'Service Item Section' ||
        name === 'Media')
    ) {
      error = `${name} field is required`;
    }
    if (
      value &&
      value.length < 2 &&
      (name == 'Name')
    ) {
      error = `The ${name} must be more than 1 character required`;
    }
    if (value && isNumeric(value) && name == 'Name') {
      error = `The ${name} must be in letters`;
    }
    if (
      value &&
      !isNumeric(value) &&
      (name == 'Price' || name == 'Weight' || name === 'Pack Quantity')
    ) {
      error = `${name} field should be in number`;
    }
    return error;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [e.target.name]: e.target.value });

    const error = validate(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleInputBlur = (identifier: any, e: any) => {
    const { name, value } = e.target;
    const error = validate(identifier, value ?? '');
    setErrors({ ...errors, [identifier]: error });
  };

  const handleSubmit = () => {
    const newErrors: any = {};
    columns.forEach((column: any) => {
      if (column.header !== 'ID') {
        const error = validate(column.header, values[column.accessorKey]);
        if (error) {
          newErrors[column.accessorKey] = error;
        }
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      createBusinessServiceItem(values);
      setValues({});
      onClose();
    }
  };

  const createBusinessServiceItem = (values: any) => {
    if (!Object.keys(validationErrors).length) {
      const type: any[] = userType.filter(
        (item: any) => item.name === 'Business',
      );
      const payload = {
        name: values?.name,
        description: values?.description,
        price: +values?.price,
        weight: +values?.weight,
        itemCode: values?.itemCode,
        packQuantity: +values?.packQuantity,
        categoryId: values?.categoryId,
        mediaItemId: values?.mediaItemId,
        type: type[0].id,
        serviceItemSectiontId: values?.serviceItemSectiontId,
      };

      httpPost(
        WASHUB_CONSTANTS.API_URLS.PRODUCT_MASTER_SERVICE_ITEM_CREATE_DATA_POST,
        payload,
      ).then((response) => {
        if (response.status === 200) {
          handleAlertBar(
            'success',
            `New Product Master Service Item Created Successfully`,
          );
          setValues({});
          onClose();
        } else {
          if (response.data.error) {
            console.error('Error create serviceitem: ', response.data.message);
          }
        }
      });
    }
  };

  const onClose = () => {
    navigate('/product-master');
  };
  const backToServiceItemList = () => {
    navigate('..');
  };
  return (
    <div style={{ width: '100%' }}>
      <Grid container>
        <Grid item m={2}>
          <Typography
            color={'primary'}
            sx={{ textAlign: 'start', cursor: 'pointer' }}
            variant="h5"
            className="mt-1"
            onClick={backToServiceItemList}
          >
            Product Master
          </Typography>
        </Grid>
        <Grid item alignContent={'center'}>
          <NavigateNextIcon />
        </Grid>
        <Grid item m={2}>
          <Typography
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-1"
          >
            Create Product Master
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />

      <div style={{ padding: '5px 15px' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
            {columns.map((column: any, index: number) =>
              column.header == 'ID' ? (
                <></>
              ) : column.header == 'Category' ? (
                <Grid item xs={12} md={6} lg={6}>
                  {' '}
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    label={column.header}
                    name={column.accessorKey}
                    select
                    required
                    onChange={handleChange}
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  >
                    {categoryData.map((option: any) => (
                      <MenuItem key={option} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : // : column.header == 'Category' ? (
              //   <Grid item xs={12} md={6} lg={6}>
              //     {' '}
              //     <TextField
              //       fullWidth
              //       key={column.accessorKey}
              //       label={column.header}
              //       name={column.accessorKey}
              //       select
              //       required
              //       onChange={handleChange}
              //       onBlur={(e) => handleInputBlur(column.accessorKey, e)}
              //       error={!!errors[column.accessorKey]}
              //       helperText={errors[column.accessorKey]}
              //     >
              //       {serviceSectionData.map((option: any) => (
              //         <MenuItem key={option} value={option?.id}>
              //           {option?.name}
              //         </MenuItem>
              //       ))}
              //     </TextField>
              //   </Grid>
              // )
              column.header == 'Item Image' ? (
                <Grid item xs={12} md={6} lg={6}>
                  {' '}
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    label={column.header}
                    name={column.accessorKey}
                    select
                    // required
                    onChange={handleChange}
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  >
                    {mediaData.map((option: any) => (
                      <MenuItem key={option} value={option?.id}>
                        <img src={option?.url} width={50} height={40} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : column.header == 'Description' ? (
                <Grid item xs={12} md={6} lg={6}>
                  {' '}
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    id={column.header}
                    label={column.header}
                    name={column.accessorKey}
                    value={values[column.accessorKey]}
                    onChange={handleChange}
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    id={column.header}
                    label={column.header}
                    name={column.accessorKey}
                    value={values[column.accessorKey]}
                    onChange={handleChange}
                    required
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  />
                </Grid>
              ),
            )}
          </Grid>
        </form>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          className="m-3"
          color="primary"
          sx={{ color: 'white' }}
          onClick={handleSubmit}
          variant="contained"
        >
          Create Product Master
        </Button>
      </div>
    </div>
  );
};
