import React, { createContext, useEffect, useMemo, useState } from 'react';
import { BusinessContextModel } from './models/BusinessContextModel';
import { WASHUB_CONSTANTS } from './Constants';
import { useAPI } from './services/api/API';

export const BusinessContext = createContext({} as BusinessContextModel);

export const BusinessProvider = ({ children }: any) => {
  const [businessList, setBusinessList] = useState<any>([]);
  const [businessStatusList, setBusinessStatusList] = useState<any>([]);
  const [retailStoreData, setRetailStoreData] = useState<any>([]);
  const type = localStorage.getItem('userType');
  const { httpGet } = useAPI();
  const queryParams = {
    page: 0,
    size: 10000,
    sortColumn: 'modifiedAt',
    isAscending: true,
  };

  // GET with API : BUSINESS
  const fetchBusiness = () => {
    let url = '';
    if (type === 'ClientAdmin') {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESS_DATA_GET}`;
    } else if (type === 'Business') {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESS_DATA_FOR_BUSINESS_GET}`;
    }
      httpGet(url, queryParams).then((response) => {
        if (response.status === 200) {
          const sortBusinessArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          setBusinessList(sortBusinessArray);
        } else {
          if (response.data.error) {
            console.error('Error fetching Business: ', response.data.message);
          }
        }
      });
  };

  const fetchOrdersStatusList = async () => {
    httpGet(WASHUB_CONSTANTS.API_URLS.ORDER_STATUS_LIST_GET).then(
      (response) => {
        if (response.status === 200) {
          const statusValues = response.data.map((item: any) => item.name);
          setBusinessStatusList(statusValues);
        } else {
          if (response.data.error) {
            console.error('Error fetching status: ', response.data.message);
          }
        }
      },
    );
  };

    // GET with API : RETAIL STORE
    const fetchRetailStore = async () => {
      let url = '';
      if( type === 'ClientAdmin') {
        url = `${WASHUB_CONSTANTS.API_URLS.RETAILSTORE_DATA_GET}`;
  
      } else if ( type === 'Retail') {
        url = `${WASHUB_CONSTANTS.API_URLS.RETAILSTORE_DATA_FOR_RETAIL_GET}`
      }
      httpGet(url, queryParams).then(
        (response: any) => {
          if (response.status === 200) {
            setRetailStoreData(response.data);
          } else {
            if (response.data.error) {
              console.error(
                'Error fetching RetailStore: ',
                response.data.message,
              );
            }
          }
        },
      );
    };

  useEffect(() => {
    if(type === 'ClientAdmin' || type === 'Business' ) {
      fetchBusiness();
    }
    if(type === 'ClientAdmin' || type === 'Retail' ) {
      fetchRetailStore();
    }
    if(type === 'ClientAdmin' || type === 'Business' || type === 'Retail' || type === 'Customer' ) {
      fetchOrdersStatusList();
    }
  }, []);

  const updateBusList = (value: any) => {
    setBusinessList(value);
  }

  return (
    <BusinessContext.Provider value={{ businessList, businessStatusList, retailStoreData, updateBusList }}>
      {children}
    </BusinessContext.Provider>
  );
};
