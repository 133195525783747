import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAPI } from '../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import {
  Button,
  Card,
  CardActions,
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { BusinessContext } from '../../../shared/BusinessContext';
import { DeliveryItemList } from './delivery-item-list/DeliveryItemList';
import { MRT_ColumnDef } from 'material-react-table';
import { BusinessDeliveryItem } from '../models/BusinessDelivery';
import { useLocation } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export const BusinessDeliveryNotes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { httpGet, httpPost, handleAlertBar } = useAPI();
  const type = localStorage.getItem('userType');
  const [businessId, setBusinessId] = useState('');
  const [businessStoreId, setBusinessStoreId] = useState('');
  const location = useLocation();
  const deliveryDetail = location.state?.deliveryDetail;

  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState('');

  const [deliveryBatchId, setDeliveryBatchId] = useState('');
  const [businessStoreData, setBusinessStoreData] = useState([]);
  const { businessList } = useContext(BusinessContext);
  const [tableData, setTableData] = useState<any>([]);
  const [ orderId, setOrderId] = useState("");

  const columns = useMemo<MRT_ColumnDef<BusinessDeliveryItem>[]>(
    () => [
      {
        accessorKey: 'orderId',
        header: 'Order Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 60,
      },
      {
        accessorKey: 'orderDate',
        header: 'Order Date',
        enableEditing: false,
        size: 60,
      },
      {
        accessorKey: 'pickupDate',
        header: 'Pickup Date',
        enableEditing: false,
        size: 60,
      },
      {
        accessorKey: 'itemCount',
        header: 'Item Count',
        enableEditing: false,
        size: 50,
      },
      {
        accessorKey: 'deliveryBatch',
        header: 'Delivery Batch',
        enableEditing: false,
        size: 60,
      },
    ],
    [],
  );

  const queryParams = {
    page: 0,
    size: 10000,
    sortColumn: 'modifiedAt'
  };

  // GET with API : BUSINESSSTORE
  const fetchBusinessStore = async (id: string) => {
    let url = '';
    if (type === 'ClientAdmin') {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_GET}`;
    } else if (type === 'Business') {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_FOR_BUSINESS_GET}`;
    }
    httpGet(url + '/' + id, queryParams).then((response) => {
      if (response.status === 200) {
        const sortBusinessStoreArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setBusinessStoreData(sortBusinessStoreArray);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  // GET with API : GET department List
  const fetchBusinessStoreDepartmentData = async (businessStoreId: string) => {
    await httpGet(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DEPARTMENT_DATA_GET}/${businessStoreId}`, queryParams
    ).then((response) => {
      if (response.status === 200) {
        const sortBusinessDeptArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setDepartmentList(sortBusinessDeptArray);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Store Department: ',
            response.data.message,
          );
        }
      }
    });
  };

  // GET with API : GET all delivery Notes for department
  const getAllBusinessDepartmentDeliveryNotes = async (
    businessStoreId: string,
    departmentId: string,
  ) => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_DEPT_ALL_DELIVERY_NOTES_GET +
        '/' +
        businessId +
        '/' +
        businessStoreId +
        '/' +
        departmentId,
    ).then((response: any) => {
      if (response.status === 200) {
        console.log(response.data);
        setTableData(response?.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStoreInvoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  // GET with API : GET all pending delivery Notes
  const getAllPendingBusinessDeliveryNotes = async () => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS
        .BUSINESSSTOREORDER_ALL_PENDING_DELIVERY_NOTES_GET +
        '/' +
        businessId +
        '/' +
        businessStoreId,
    ).then((response: any) => {
      if (response.status === 200) {
        setTableData(response?.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStoreInvoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  // GET with API : GET all pending delivery Notes for department
  const getAllPendingBusinessDepartmentDeliveryNotes = async (
    departmentId: string,
  ) => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS
        .BUSINESSSTOREORDER_DEPT_ALL_PENDING_DELIVERY_NOTES_GET +
        '/' +
        businessId +
        '/' +
        businessStoreId +
        '/' +
        departmentId,
    ).then((response: any) => {
      if (response.status === 200) {
        setTableData(response?.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStoreInvoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  const getAllPendingDepartmentNote = () => {
    if (departmentId) {
      getAllPendingBusinessDepartmentDeliveryNotes(departmentId);
    }
  };

  // GET with API : GET all pending delivery Notes
  const getAllCompletedBusinessDeliveryNotes = async () => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS
        .BUSINESSSTOREORDER_ALL_COMPLETED_DELIVERY_NOTES_GET +
        '/' +
        businessId +
        '/' +
        businessStoreId,
    ).then((response: any) => {
      if (response.status === 200) {
        setTableData(response?.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStoreInvoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  // GET with API : GET all pending delivery Notes for department
  const getAllCompletedBusinessDepartmentDeliveryNotes = async (
    departmentId: string,
  ) => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS
        .BUSINESSSTOREORDER_DEPT_ALL_COMPLETED_DELIVERY_NOTES_GET +
        '/' +
        businessId +
        '/' +
        businessStoreId +
        '/' +
        departmentId,
    ).then((response: any) => {
      if (response.status === 200) {
        setTableData(response?.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStoreInvoices: ',
            response.data.message,
          );
        }
      }
    });
  };

    // POST with API : Generate delivery Notes
    const fetchGenerateDeliveryNotesBatch = async (rowData: any) => {
      httpPost(
        WASHUB_CONSTANTS.API_URLS
          .BUSINESSSSTORE_GENERATE_DELIVERY_NOTE_BATCH_POST +
          '/' +
          rowData.orderId ,
        null,
      ).then((response) => {
        if (response.status === 200) {
          getAllBusinessDepartmentDeliveryNotes(rowData.businessStore, rowData.businessDepartment)
        } else {
          if (response.data.error) {
            console.error(
              'Error fetching BusinessStoreInvoices: ',
              response.data.message,
            );
          }
        }
      });
    };

  const getAllCompletedDepartmentNote = () => {
    if (departmentId) {
      getAllCompletedBusinessDepartmentDeliveryNotes(departmentId);
    }
  };

  // Business Store
  useEffect(() => {
    if (businessId || deliveryDetail?.business) {
      fetchBusinessStore(businessId || deliveryDetail?.business);
      setOrderId("");
    }
  }, [businessId]);

  useEffect(() => {
    if (deliveryDetail) {
      setBusinessId(deliveryDetail?.business);
      fetchBusinessStore(deliveryDetail?.business);
      setBusinessStoreId(deliveryDetail?.businessStore);
      setDepartmentId(deliveryDetail?.businessDepartment);
    }
  }, [deliveryDetail]);

  useEffect(() => {
    if (businessStoreId || deliveryDetail?.businessStore) {
      setOrderId("");
      fetchBusinessStoreDepartmentData(
        businessStoreId || deliveryDetail?.businessStore,
      );
    }
  }, [businessStoreId]);

  useEffect(() => {
    if (departmentId || deliveryDetail?.businessDepartment) {
      setOrderId("");
      getAllBusinessDepartmentDeliveryNotes(businessStoreId,
        departmentId || deliveryDetail?.businessDepartment,
      );
    }
  }, [departmentId]);

  const refetch = () => {
    setOrderId("");
    if(departmentId) {
      getAllBusinessDepartmentDeliveryNotes(businessStoreId,departmentId);
    }
  }

  const fetchBusinessDeliveryOrderById = useMemo(
    () =>
      debounce((orderId: string, businessId: string, businessStoreId: string, departmentId: string) => {
        const payload = {
          businessId: businessId,
          businessStoreId: businessStoreId,
          businessDeptId: departmentId,
          orderId: orderId
        }
        if(businessId) {
          httpPost(
            WASHUB_CONSTANTS.API_URLS.SEARCH_BUSINESS_DELIVERY_NOTES_BY_ORDERID_POST, payload
          ).then((response) => {
            if (response.status === 200) {
              setTableData(response.data);
            } else {
              if (response.data.error) {
                handleAlertBar(
                  'error',
                  response?.data?.message
                );
              }
            }
          });

        } else {
          handleAlertBar(
            'error',
            "Please Select business then search order Id"
          );
        }
      }, 700),
    []
  );

  return !isLoading ? (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <Grid container >
          <Grid item className='mb-1 mt-1 ms-3'>
            <Typography sx={{textAlign: 'center'}} variant='h5' className='mt-0'>Business Delivery Notes</Typography>
          </Grid>
      </Grid>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '2px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <TextField
          size="small"
          key="business"
          style={{ width: '30%' }}
          label="Select Business"
          name="business"
          select
          onChange={(e) => {
            setBusinessId(e.target.value);
          }}
          value={businessId}
        >
          {businessList?.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
          <TextField
            size="small"
            style={{ width: '30%' }}
            key="businessStore"
            label="Select Business Store"
            name="businessStore"
            select
            onChange={(e) => {
              setBusinessStoreId(e.target.value);
            }}
            value={businessStoreId}
            disabled={businessId === ''}
          >
            {businessStoreData.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            size="small"
            style={{width: '30%'}}
            key="department"
            label="Select Department"
            name="department"
            select
            onChange={(e) => {
              setDepartmentId(e.target.value);
            }}
            value={departmentId}
            disabled={businessStoreId === ''}
          >
            {departmentList.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          padding: '8px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
      <div className='d-flex justify-content-between' style={{width: "85%"}}>
        <TextField
            size="small"
            key="orderId"
            style={{ width: '35%',}}
            label="Select Order Id"
            name="orderId"
            onChange={(e) => {
              setOrderId(e.target.value);
              fetchBusinessDeliveryOrderById(e.target.value, businessId, businessStoreId, departmentId);
            }}
            variant='outlined'
            value={orderId}
            InputProps={{
              endAdornment: (
                orderId && (
                  <InputAdornment position="end">
                    <Link component="button" title={"Clear"}>
                      <CloseIcon
                        fontSize="small"
                        onClick={() => {setOrderId(""); refetch()}}
                      />
                    </Link>
                  </InputAdornment>
                )
              ),
              startAdornment:(
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              )
            }}
          >
          </TextField>
          {departmentId ? (
            <React.Fragment>
              <Button
                    sx={{color: 'white'}}
                    variant="contained"
                    onClick={() => getAllPendingDepartmentNote()}
                  >
                    Pending Delivery Note Batches
                  </Button>

                  <Button
                    sx={{color: 'white'}}
                    variant="contained"
                    onClick={() => getAllCompletedDepartmentNote()}
                  >
                    Completed Delivery Note Batches
                  </Button>
                  </React.Fragment>
      ) : null}
      </div>
        <Tooltip arrow title="Refresh Table Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <DeliveryItemList columns={columns} tableData={tableData} businessId={businessId} businessStoreId={businessStoreId} departmentId={departmentId} fetchGenerateDeliveryNotesBatch={fetchGenerateDeliveryNotesBatch} orderId={orderId}/>
    </div>
  ) : null;
};
