import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { MaterialReactTable, MRT_RowSelectionState } from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { useAPI } from '../../../../shared/services/api/API';
import { DeptServiceItemDetails } from '../../models/BusinessDepartmentModel';
import CloseIcon from '@mui/icons-material/Close';

export const AddServiceItemDept = (Props?: any) => {
  const { handleAlertBar, httpGet, httpPost } = useAPI();
  const [tableData, setTableData] = useState<DeptServiceItemDetails[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const queryParams = {
    page: 0,
    size: 10000,
    sortColumn: 'modifiedAt'
  };
  // GET with API
  const fetchServiceItem = async (businessId: string) => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.SERVICEITEM_BUSINESS_DATA_GET}/${businessId}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setTableData((): any => response?.data);
      } else {
        if (response.data.error) {
          console.error('Error fetching serviceitem: ', response.data.message);
          setTableData([]);
        }
      }
    });
  };
  // Business Store
  useEffect(() => {
    if (Props?.openDialog && Props?.businessId) {
      fetchServiceItem(Props?.businessId);
    }
  }, [Props?.businessId, Props?.openDialog]);
  return (
    <Dialog maxWidth={'lg'}open={Props?.openDialog} onClose={Props?.handleCloseDialog}>
      <DialogTitle
        sx={{ backgroundColor: '#83CBEB', color: '#ffff' }}
        variant="h5"
        className="mb-3 p-3"
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Typography textAlign={'center'} variant='h5' className='px-1 mt-2'>Add Service Items to Department</Typography>
        <IconButton aria-label="close" onClick={Props?.handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          columns={Props?.columns}
          data={tableData}
          enableFullScreenToggle={false}
          enableSorting={false}
          enableHiding={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          initialState={{showGlobalFilter: true}}
          enableGlobalFilterModes
          muiSearchTextFieldProps={{
            placeholder: `Search Item name`,
            sx: { minWidth: '300px' },
            variant: 'outlined',
          }}
          enableStickyHeader
          enableRowSelection
          getRowId={(row) => row?.id} 
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
          muiTableContainerProps={{
            sx: {
              height: '49vh',
            },
          }}
        />
      </DialogContent>
      <DialogActions className="dialog-btn">
        <Button
          onClick={() => Props?.handleMapServiceItem(rowSelection)}
          color="primary"
          variant="contained"
          sx={{color: "#ffff"}}
        >
          Map Service Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};
