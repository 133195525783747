import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../shared/services/api/API';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import {
  MaterialReactTable,
  MaterialReactTableProps,
  MRT_ColumnDef,
  MRT_EditActionButtons,
} from 'material-react-table';
import { PriceMasterItemDetails } from '../../models/ProductMaster';
import { Delete, Edit } from '@mui/icons-material';
import { AlertDialog } from '../../../../shared/components/alert-dialog/AlertDialog';

export const PriceMaster = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const busStoreId = location?.state?.busStoreId;
  const { httpGet, httpPut, httpPost, httpDelete, handleAlertBar } = useAPI();
  const [businessStoreId, setBusinessStoreId] = useState('');
  const [businessStoreData, setBusinessStoreData] = useState<any>([]);
  const [departmentId, setDepartmentId] = useState<string[]>([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [tableData, setTableData] = useState<PriceMasterItemDetails[]>([]);
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const [deletePriceMasterData, setDeletePriceMasterData] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [associateDeptPayload, setAssociateDeptPayload] = useState<any>([]);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

      // GET with API : BUSINESSSTORE
      const fetchBusinessStore = async () => {
        httpGet(
          `${WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_BUSINESS_STORE_DATA_GET}`,
          queryParams,
        ).then((response) => {
          if (response.status === 200) {
            const sortBusinessStoreArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            setBusinessStoreData(sortBusinessStoreArray);
          } else {
            if (response.data.error) {
              console.error(
                'Error fetching BusinessStore: ',
                response.data.message,
              );
            }
          }
        });
      };

  const handleChangeDepartment = (
    event: SelectChangeEvent<typeof departmentId>,
  ) => {
    const {
      target: { value },
    } = event;
    console.log(value)
    setDepartmentId(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const queryParams = {
    page: 0,
    size: 10000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };

  // GET with API : BUSINESSSTORE
  const fetchDepartmentStore = async (busStoreId: string) => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_BUSINESS_DEPARTMENT_DATA_GET}/${busStoreId}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        const sortBusinessDeptArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setDepartmentList(sortBusinessDeptArray);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  // GET with API
  const fetchPriceMaster = async (businessStoreId: string) => {
    setIsLoading(true);
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_SERVICE_ITEM_FOR_BUSINESSSTORE_DATA_GET}/${businessStoreId}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setTableData((): any => response?.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error('Error fetching serviceitem: ', response.data.message);
          setTableData([]);
          setIsLoading(false);
        }
      }
    });
  };
  // PUT with API
  const handleSaveRowEdits: MaterialReactTableProps<PriceMasterItemDetails>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        const payload = {
          storeId: row?.original?.businessStore?.id,
          serviceItemId: row?.original?.serviceItem?.id,
          customerDescription:
            valuesDetail?.customerDescription ||
            row.original?.customerDescription,
          price: +valuesDetail?.price || values?.price,
          weight: +valuesDetail?.weight || values?.weight,
          packQuantity: +valuesDetail?.packQuantity || values?.packQuantity,
        };

        httpPut(
          WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_SERVICE_ITEM_UPDATE_DATA_PUT +
            '/' +
            row.original.id,
          payload,
        ).then((response) => {
          if (response.status === 200) {
            fetchPriceMaster(businessStoreId);
            handleAlertBar('success', `Price Master Item updated Successfully`);
            setDepartmentId([]);
            setValuesDetail({});
            exitEditingMode(); //required to exit editing mode and close modal
          } else {
            if (response.data.error) {
              handleAlertBar('error', response.data.message);
              console.error(
                'Error Updating serviceitem: ',
                response.data.message,
              );
            }
          }
        });
      }
    };
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };
  const handleAssociateDepartment = (row: any) => {
    setBusinessStoreId(row?.businessStore?.id);
    setAssociateDeptPayload(row);
    setOpenDialog(true);
    getAllDeptMapPriceMaster(row);

  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setDepartmentId([]);
  };

  const columns = useMemo<MRT_ColumnDef<PriceMasterItemDetails>[]>(
    () => [
      {
        accessorKey: 'customerDescription',
        header: 'Customer Description',
        size: 140,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        size: 50,
      },
      {
        accessorKey: 'weight',
        header: 'Weight',
        size: 50,
      },
      {
        accessorKey: 'packQuantity',
        header: 'Pack Quantity',
        size: 50,
      },
    ],
    [],
  );
  const detailPriceMaster = (row: any) => {
    const column = [
      {
        accessorKey: 'customerDescription',
        header: 'Customer Description',
        size: 140,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        size: 50,
      },
      {
        accessorKey: 'weight',
        header: 'Weight',
        size: 50,
      },
      {
        accessorKey: 'packQuantity',
        header: 'Pack Quantity',
        size: 50,
      },
    ];
    navigate('price-master-detail', {
      state: {
        rowData: row?.original,
        column: column,
      },
    });
  };
  const [valuesDetail, setValuesDetail] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const handleDeletePriceMaster = (row: any) => {
    setDeletePriceMasterData(row);
    setIsAlertDialog(true);
  };
  const handleAlertDialogClose = () => {
    setIsAlertDialog(false);
  };

  const deletePriceMaster = () => {
    httpDelete(
      `${WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_SERVICE_ITEM_REMOVE_DATA_DELETE}/${deletePriceMasterData?.id}`,
    )
      .then((response) => {
        if (response?.status === 200) {
          fetchPriceMaster(businessStoreId);
          handleAlertDialogClose();
          handleAlertBar('success', `Price Master deleted sucessfully`);
        }
      })
      .catch((error: any) => {
        handleAlertBar('error', error);
      });
  };

  const associateDeptWithPriceMaster = () => {
    const deptIdArray = departmentList
      ?.filter((item: any) => departmentId?.includes(item?.name))
      ?.map((e: any) => e?.id);
    const payload = {
      storeId: associateDeptPayload?.businessStore?.id,
      serviceItemId: associateDeptPayload?.serviceItem?.id,
      priceMasterId: associateDeptPayload?.id,
      departmentId: deptIdArray,
    };

    httpPost(
      WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_ASSOCIATE_DEPT_DATA_POST,
      payload,
    ).then((response) => {
      if (response.status === 200) {
        fetchPriceMaster(businessStoreId);
        handleAlertBar(
          'success',
          `Departments associated with this price master Successfully`,
        );
        setDepartmentId([]);
        setOpenDialog(false);
      } else {
        if (response.data.error) {
          handleAlertBar('error', response.data.message);
          console.error('Error Updating serviceitem: ', response.data.message);
        }
      }
    });
  };

  const getAllDeptMapPriceMaster = (row: any) => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_MAPPER_DEPT_DATA_GET}/${row?.id}`,
    ).then((response) => {
      if (response.status === 200) {
        const mappedDept = response?.data?.map((res: any) => res?.businessDepartment?.name);
        setDepartmentId(mappedDept);
      } else {
        if (response.data.error) {
          handleAlertBar('error', response.data.message);
          console.error('Error Updating serviceitem: ', response.data.message);
        }
      }
    });
  };

  useEffect(() => {
    if (businessStoreId || busStoreId) {
      setBusinessStoreId(businessStoreId || busStoreId);
      fetchDepartmentStore(businessStoreId || busStoreId);
      fetchPriceMaster(businessStoreId || busStoreId);
    }
  }, [businessStoreId || busStoreId]);

  const createNewPriceMaster = () => {
    const columns = [
        {
          accessorKey: 'customerDescription',
          header: 'Customer Description',
          size: 50,
        },
        {
          accessorKey: 'price',
          header: 'Price',
          size: 50,
        },
        {
          accessorKey: 'weight',
          header: 'Weight',
          size: 50,
        },
        {
          accessorKey: 'packQuantity',
          header: 'Pack Quantity',
          size: 50,
        }
      ];
    navigate('create-price-master', {
      state: {
        column: columns,
        businessStoreId: businessStoreId,
        businessStoreData: businessStoreData
      },
    });
  };

  useEffect(() => {
    fetchBusinessStore();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <Grid container justifyContent={'space-between'}>
        <Grid item className="mb-1 mt-1 ms-3">
          <Typography
            color={'primary'}
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-0"
          >
            Price Master
          </Typography>
        </Grid>
        <Grid item className="mb-1 mt-1 me-3">
          <Button
            color="primary"
            sx={{ color: 'white' }}
            onClick={() => createNewPriceMaster()}
            variant="contained"
            className="mt-0"
          >
            Create Price Master
          </Button>
        </Grid>
      </Grid>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '2px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <TextField
          size="small"
          style={{ width: '30%' }}
          key="businessStoreId"
          label="Select Business Store"
          name="businessStoreId"
          select
          onChange={(e) => {
            setBusinessStoreId(e.target.value);
          }}
          value={businessStoreId}
        >
          {businessStoreData.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

      </Stack>
      <div style={{ padding: '5px 15px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          columns={columns}
          positionActionsColumn="last"
          data={tableData}
          state={{ isLoading: isLoading }}
          enableFullScreenToggle={false}
          enableStickyHeader
          enableColumnOrdering
          enableEditing
          enableHiding
          editDisplayMode="modal"
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          muiTableContainerProps={{
            sx: {
              height: '57vh',
            },
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="right" title="Associate Dept">
                <IconButton
                  onClick={() => handleAssociateDepartment(row?.original)}
                >
                  <img src="images/associate.png" width={22} height={20} />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  onClick={() => handleDeletePriceMaster(row?.original)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
              <Button
                className='ps-1 pe-1'
                variant="contained"
                sx={{color: 'white'}}
                onClick={() => detailPriceMaster(row)}
                >
                  <Typography variant='labelNormal'>Details</Typography>
              </Button>
            </Box>
          )}
        />
      </div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle textAlign="center">Associate Department</DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: '100%', marginTop: "5px"}}>
            <InputLabel id="demo-multiple-name-label">
              Select Department*
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              multiple
              value={departmentId || ''}
              onChange={handleChangeDepartment}
              input={<OutlinedInput label="Select Department" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {departmentList.map((option: any) => (
                <MenuItem key={option.name} value={option.name}>
                  <Checkbox checked={departmentId?.includes(option.name)} />
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className="dialog-btn">
          <Button
            color="error"
            variant="contained"
            sx={{ color: 'white' }}
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            onClick={associateDeptWithPriceMaster}
            sx={{ color: 'white' }}
            variant="contained"
          >
            Mapped Department
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={isAlertDialog}
        title={'Delete Price Master'}
        description={'Are you sure you want to delete this price master ?'}
        submitBtnText={'Delete'}
        cancelBtnText={'Cancel'}
        handleSubmit={deletePriceMaster}
        handleClose={handleAlertDialogClose}
      ></AlertDialog>
    </div>
  );
};
