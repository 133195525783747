import { Box, Button, IconButton,Link,Typography } from '@mui/material';
import { MRT_ColumnDef,MaterialReactTable} from 'material-react-table';
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { useAPI } from '../../../../shared/services/api/API';
import { SharedApi } from '../../../../shared/services/shared-api/SharedApi';
import DescriptionIcon from '@mui/icons-material/Description';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { AlertDialog } from '../../../../shared/components/alert-dialog/AlertDialog';

export type BusinessStoreOrderDeatils = {
  id: string;
  name: string;
  description: string;
  price: string;
  categoryId: string;
  mediaItemId: string;
  orderId: string;
  orderDate: string;
  orderStatus: string;
  orderNote: string;
};

export const BusinessOrderList = (Props: any) =>{
    const navigate = useNavigate();
    const { axiosInstance, httpPost, handleAlertBar } = useAPI();
    const [ statusOptions, setStatusOptions] = useState([]);
    const { getStatusOptions } = SharedApi();
    const [isAlertDialog, setIsAlertDialog] = useState(false);
    const [cancelBusinessOrderData, setCancelBusinessOrderData] = useState<any>([]);

    const columns = useMemo<MRT_ColumnDef<BusinessStoreOrderDeatils>[]>(
      () => [
        {
          accessorKey: 'orderId',
          header: 'OrderId',
          enableColumnOrdering: false,
          enableEditing: false, //disable editing on this column
          enableHiding: false,
          enableSorting: false,
          size: 50,
        },
        {
          accessorKey: 'businessDepartment.name',
          header: 'Department',
          enableEditing: false,
          size: 180,
          grow: false,
          muiTableBodyCellProps: ({
            cell
          }) => ({
            sx: {
                maxWidth: '1vw',
                width: '1vw',
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap"
            }
          }),
          Cell: ({cell}) => (
              <Typography variant='labelNormal' title={cell.getValue<string>()} > {cell.getValue<string>()}</Typography>
          ),
        },
        {
          accessorKey: 'orderDate',
          header: 'Date',
          enableEditing: false,
          size: 50,
        },
        {
          accessorKey: 'orderNote',
          header: 'Note',
          enableEditing: false,
          size: 50,
          Cell: ({row, table}) => (
            <Box>
              <IconButton color='primary' onClick={() => table.setExpanded({ [row.id]: !row.getIsExpanded()}) } disabled={row.original.orderNote ? false : true}>
                {row.getIsExpanded() ? <ImportContactsIcon/> : <DescriptionIcon />}
              </IconButton>
            </Box>
          ),
        },
        {
          accessorKey: 'totalItemCount',
          header: 'Item Count',
          enableEditing: false,
          size: 70,
        },
        {
          accessorKey: 'orderStatus',
          header: 'Status',
          editVariant: 'select',
          size: 90,
          editSelectOptions: statusOptions,
          muiEditTextFieldProps: ({ cell, row }) => ({
            select: true,
            onChange: (value: any) => handleStatusChange(value,row.original),
          }),
          Cell: ({cell}) => (
            <Typography sx={{
              backgroundColor: cell.getValue<string>() === 'CANCELLED'
                ? 'red'
                : cell.getValue<string>() === 'DELIVERED'
                ? '#83d37b'
                : cell.getValue<string>() === 'DELIVERED_PARTIAL'
                ? 'teal'
                : cell.getValue<string>() === 'READYTODELIVER'
                ? '#FFE5B4'
                : cell.getValue<string>() === 'READYTODELIVER_PARTIAL'
                ? 'yellow'
                : 'none',
                p: 1
            }} variant='labelNormal' title={cell.getValue<string>()} > {cell.getValue<string>()}</Typography>
        ),
        },
      ],
      [statusOptions],
    );

    const handleStatusChange =(event: any, row: any) =>{
      const payload = {
        orderId: row.orderId,
        orderItemId: 0,
        status: event.target.value,
        processCount: row.totalItemCount
      }
      httpPost(
        `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_STATUS_UPDATE_POST}`,
        payload,
      ).then((response) => {
          if (response?.status === 200) {
              handleAlertBar("success", `Status updated successfully`);
            }
          })
        .catch((error: any) => {
          console.log('error', error);
          handleAlertBar("error", error);
        });
  
    }

    const handleCancelBusiness = (row: any) => {
      setCancelBusinessOrderData(row);
      setIsAlertDialog(true);
    };
    const handleAlertDialogClose = () => {
      setIsAlertDialog(false);
    };

    const cancelBusinessOrder =() =>{
      console.log(cancelBusinessOrderData)
      const reqBody = {
        businessId: cancelBusinessOrderData?.business?.id,
        businessStoreId: cancelBusinessOrderData?.businessStore?.id,
        departmentId: cancelBusinessOrderData?.businessDepartment?.id,
        serviceTypeId: cancelBusinessOrderData?.serviceType?.id,
        pickupDate: cancelBusinessOrderData?.pickupDate,
        deliveryDate: cancelBusinessOrderData?.deliveryDate,
        orderNote: cancelBusinessOrderData?.orderNote,
        billNo: cancelBusinessOrderData?.billNo,
        serviceItemOrder: cancelBusinessOrderData?.serviceItemOrder
      }
      httpPost(
        `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_CANCEL_POST}/${cancelBusinessOrderData?.orderId}`,
        reqBody,
      ).then((response) => {
          if (response?.status === 200) {
              if(Props?.orderId) {
                Props?.fetchBusinessOrderById(Props?.orderId, Props?.businessId,Props?.businessStoreId, Props?.departmentId);
              } else if( Props?.departmentId && Props?.businessStoreId) {
                Props?.fetchBusinessDepartmentOrder(Props?.departmentId);
              } else if(Props?.businessStoreId){
                Props?.fetchBusinessStoreOrder(Props?.businessStoreId)
              }
              handleAlertDialogClose();
              handleAlertBar("success", `Business Order cancelled successfully`);
            }
          })
        .catch((error: any) => {
          handleAlertBar("error", error);
        });
  
    }

    const generateOrderNote = (rowData: any) => {
      const payload = {
        orderId: rowData?.orderId,
        businessId: rowData?.business?.id,
        businessStoreId: rowData?.businessStore?.id,
        departmentId: rowData?.businessDepartment?.id
      }
        axiosInstance.post(
          WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_GENERATE_ORDER_NOTE_POST,
          payload,
          { 
            headers: {
              Authorization: localStorage.getItem("jwtToken") || null
            },
            responseType: 'blob'}
        ).then((response: any) => {
          if (response.status === 200) {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const contentDisposition = response.headers['content-disposition'];
            let fileName = `business-order-note-${rowData?.orderId}`;
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            console.log(contentDisposition);
            a.setAttribute('download', fileName);
            document.body.appendChild(a);
            a.click();
    
            a.remove();
            window.URL.revokeObjectURL(url)
          } else {
            if (response.data.error) {
              console.error(
                'Error fetching BusinessStoreInvoices: ',
                response.data.message,
              );
            }
          }
        });
    }

  return (
    <div style={{padding:"5px 15px"}}>
    <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 200,
          },
        }}
        state={{ isLoading: Props?.IsLoading }}
        muiTableContainerProps={{
          sx: {
            height: '60vh',
          },
        }}
       layoutMode="grid"
       defaultColumn={{
        maxSize: 150,
        minSize: 10,
        size: 50
      }}
        muiTableBodyCellProps={{
          sx: {
            p: '2px 8px 2px 16px'
          }
        }}
        muiTablePaperProps={{
          sx: {
            width: '100%',
            '& .css-1tbggly': {
              minHeight: '2px',
            },
          },
        }}
        enableDensityToggle={false}
        columns={columns}
        data={Props?.tableData}
        editDisplayMode="cell" // ('modal', 'cell', 'table', and 'custom' are also available)
        enableEditing={true} // ('modal', 'row', 'cell', and 'custom' are also available)
        positionActionsColumn="last"
        enableRowActions
        enableFullScreenToggle={false}
        enableStickyHeader
        enableExpandAll={false}
        enableHiding={false}
        enableColumnFilters={false}
        initialState={{columnVisibility: {'mrt-row-expand': false}}}
        localization={{noRecordsToDisplay: Props?.businessStoreId || (Props?.orderId && Props?.businessId) ? 'No data Available' : 'Select a business & business store to display records'}}
        renderDetailPanel= {({ row }) =>
          row.original.orderNote ? (
            <Box
              sx={{
                display: 'grid',
                margin: 'auto',
                gridTemplateColumns: '1fr 1fr',
                width: '100%',
              }}
            >
              <Typography><span className='fw-bold'>Order Note: </span><span>{row.original.orderNote}</span></Typography>
            </Box>
          ) : null}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex',flexWrap: 'nowrap', gap: '0.5rem'}}>
            <Button
              className='ps-1 pe-1'
              variant="contained"
              sx={{color: 'white'}}
              onClick={()=> {
                table.setEditingRow(row);
                setStatusOptions(getStatusOptions(row.original.orderStatus))
              }}
              disabled={row.original.orderStatus === "PAYMENTDONE"}
            >
              <Typography variant='labelNormal'>Update</Typography>
            </Button>
            <Button
              className='ps-1 pe-1'
              sx={{color: 'white', whiteSpace: 'nowrap'}}
              variant="contained"
              onClick={()=> {
                generateOrderNote(row.original);
              }}
            >
              <Typography variant='labelNormal'>Pickup Note</Typography>
            </Button>
            <Button
              className='ps-1 pe-1'
              variant="contained"
              sx={{color: 'white'}}
              onClick={() => {
                navigate('order-details', {
                  state: {
                    orderType: Props?.OrderType.Business,
                    order: row?.original,
                  },
                });
              }}>
                <Typography variant='labelNormal'>Details</Typography>
            </Button>
            <Button
              className='ps-1 pe-1'
              variant="contained"
              sx={{color: 'white'}}
              onClick={() => {handleCancelBusiness(row?.original)}}>
                <Typography variant='labelNormal'>Cancel</Typography>
            </Button>
          </Box>
        )}
        enableToolbarInternalActions={false}
      />
      <AlertDialog
        open={isAlertDialog}
        title={'Cancel Business Order'}
        description={'Are you sure you want to cancel this business order ?'}
        submitBtnText={'Cancel'}
        cancelBtnText={'Close'}
        handleSubmit={cancelBusinessOrder}
        handleClose={handleAlertDialogClose}
    ></AlertDialog>
    </div>

  )
}
