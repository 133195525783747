import React, { useState, useEffect, useContext } from 'react';
import { Divider, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useAPI } from '../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../shared/Constants';
import Order from './components/Order';
import {
  ICartData,
  ILaundryCartProps,
  LaundryCartContext,
} from './context/service.context';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  IBusinessStore,
  IOrderItem,
} from '../../shared/models/Order.model';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { BusinessContext } from '../../shared/BusinessContext';

export type OrdersDeatils = {
  id: string;
  name: string;
  description: string;
  price: string;
  categoryId: string;
  mediaItemId: string;
};

export const BusinessOrder = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editOrder = location?.state?.edit;
  const BusinessStoreOrder = location?.state?.order;
  const { httpGet, httpPost } = useAPI();
  const [businessId, setBusinessId] = useState<any>('');
  const { businessList } = useContext(BusinessContext);
  const [orderId, setOrderId] = useState(BusinessStoreOrder?.orderId);
  const [businessStoreId, setBusinessStoreId] = useState('');
  const [businessStoreData, setBusinessStoreData] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState('');
  const [serviceData, setServiceData] = useState([]);
  const type = localStorage.getItem('userType');
  const {
    data: cartData,
    setData,
    setClientInfo,
  } = useContext(LaundryCartContext);

  // Business Store
  useEffect(() => {
    fetchBusinessStore();
  }, []);

  const queryParams = {
    page: 0,
    size: 10000,
    sortColumn: "modifiedAt",
    isDescending: true
  };

  // Selected Business Store
  useEffect(() => {
    if (businessStoreId) {
      fetchBusinessStoreDepartmentData(businessStoreId);
      if (businessStoreData.length) {
        const businessStore: IBusinessStore | undefined =
          businessStoreData.find(
            (e: IBusinessStore) => +e?.id === +businessStoreId,
          ) as unknown as IBusinessStore;

        if (businessStore) {
          setBusinessId(businessStore?.business?.id)
          setClientInfo((pv: ILaundryCartProps['clientInfo']) => {
            return {
              ...pv,
              userName: businessStore?.name,
              userId: businessStore?.id,
              address: [
                businessStore?.address,
                businessStore?.address2,
                businessStore?.city,
                businessStore?.state,
                businessStore?.zipCode,
              ].join(', '),
              phoneNumber: businessStore?.phone,
            };
          });
        }
      }
      // fetchServiceItem(businessStoreId);
    }
  }, [businessStoreId, businessStoreData]);

  // edit order
  useEffect(() => {
    if (BusinessStoreOrder?.businessStore?.id) {
      setBusinessStoreId(BusinessStoreOrder?.businessStore?.id);
    }
    if (BusinessStoreOrder?.department?.id) {
      setDepartmentId(BusinessStoreOrder?.department?.id);
    }
  }, [BusinessStoreOrder]);

  useEffect(() => {
    if (departmentId) {
      fetchBusinessStoreDepartmentServiceItem(departmentId);
    }
  }, [departmentId]);

  // Service Item Order
  useEffect(() => {
    if (serviceData?.length && orderId) {
      fetchOrders(orderId);
    } else {
      setData(() => serviceData);
    }
  }, [serviceData, orderId]);

  // GET with API : BUSINESS STORE
  const fetchBusinessStore = async () => {
    httpGet(WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_V2_ADMINSERVICEITEM_DATA_FOR_BUSINESS_GET, queryParams).then(
      (response) => {
        if (response.status === 200) {
          const sortBusinessStoreArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          setBusinessStoreData(sortBusinessStoreArray);
        } else {
          if (response.data.error) {
            console.error(
              'Error fetching BusinessStore: ',
              response.data.message,
            );
          }
        }
      },
    );
  };

  const fetchBusinessStoreDepartmentServiceItem = async (deptId: any) => {
    await httpGet(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESS_SERVICEITEM_V2_ADMINSERVICEITEM__DATA_FOR_BUSINESSDEPT_GET}/${businessStoreId}/${deptId}`
    ).then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
          setServiceData((): any => transformData(response?.data?.retailServiceItem));
      }
    })
    .catch(() => {
      setData(() => []);
    });
  }

  const fetchBusinessStoreDepartmentData = async (businessStoreId: any) => {
    await httpGet(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESS_DEPARTMENT_V2_ADMINSERVICEITEM_DATA_FOR_BUSINESS_GET}/${businessStoreId}`,queryParams
    ).then((response) => {
      if (response.status === 200) {
        const sortBusinessDeptArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setDepartmentList(sortBusinessDeptArray);
        if (location.state?.orderDetails?.departmentId) {
          setDepartmentId(location.state?.orderDetails?.departmentId);
          if( type === 'Business') { 
            fetchBusinessStoreDepartmentServiceItem(location.state?.orderDetails?.departmentId);
          } else { 
            fetchServiceItem(location.state?.orderDetails?.departmentId);
          }
        }
        // setServiceData([]);
        // setData([])
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Store Department: ',
            response.data.message,
          );
        }
      }
    });
  };

  // GET with API
  const fetchServiceItem = async (businessStoreId: any) => {
    httpGet(`${WASHUB_CONSTANTS.API_URLS.BUSINESS_SERVICEITEM_V2_ADMINSERVICEITEM__DATA_FOR_BUSINESSSTORE_GET}/${businessStoreId}`)
      .then((response: AxiosResponse<any>) => {
        if (response.status === 200) {
            setServiceData((): any => transformData(response?.data?.retailServiceItem));
        }
      })
      .catch(() => {
        setData(() => []);
      });
  };

  const transformData = (data: any) =>{
    return  data?.reduce((acc: any, item: any) => {
      // item?.serviceItemSection?.forEach((service: any, index: number)=> {
        item?.optiServiceItem.forEach((optiServiceItem: any, i: number)=>{
          acc.push({
          item: optiServiceItem?.name,
          price: optiServiceItem?.price,
          currency: 'AED: ', // required in BE
          quantity: 0,
          category: optiServiceItem?.category?.name,
          icon: optiServiceItem?.mediaItem?.url,
          id: optiServiceItem?.id,
          itemId: optiServiceItem?.id,
          categoryId: optiServiceItem?.category?.id,
          serviceItemSectionName: item?.serviceItemSectionName,
          itemCode: optiServiceItem?.itemCode
        });
      // });
    });
    return acc;
  }, []);
  }

  // GET with API : Business Store Order
  const fetchOrders = async (id: string) => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_DETAILS_DATA_GET + '/' + id,
    )
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.orderItems?.length) {
            /* edit order */
            serviceData.map((item: any) => {
              const orderItem = response?.data?.orderItems.find(
                (e: IOrderItem) => e?.serviceItem.id === item?.itemId,
              );
              if (orderItem) {
                item.quantity = orderItem?.itemCount;
              }
            });
            setData(() => serviceData);
          } else {
            setData(() => serviceData);
          }
        }
      })
      .catch(() => {
        resetClientInfo();
      });
  };

  // POST: Create and Edit Business Store Order
  const createOrder = () => {
    const itemData = cartData.filter((item: any) => item?.quantity > 0);
    const payload = {
      businessId: businessId,
      businessStoreId: businessStoreId,
      departmentId: departmentId,
      serviceItemOrder: itemData.map((item: any) => {
        return {
          specialInstructions: 'string',
          amount: +item?.price * +item?.quantity,
          itemCount: item?.quantity,
          categoryId: item?.categoryId,
          serviceItemId: item?.itemId,
        };
      }),
    };
    navigate("../order-summary", { state: { payload: payload, orderId: orderId, itemData: itemData }});
  };

  // Reset Client Info (cart summary)
  const resetClientInfo = () => {
    setClientInfo((pv: any) => {
      return {
        ...pv,
        userName: '',
        userId: '',
        address: '',
        phoneNumber: '',
      };
    });
  };

  const backToBusinessOrdersList = () => {
    navigate('..', { state: { order: {
      business: {id: businessId},
      businessStore: {id: businessStoreId},
      department: {id: departmentId}
  }} });
  }

  return (
    <div style={{ height:'89vh', overflow: 'auto'}}>
      <Grid container>
          <Grid item className='mb-1 mt-1 ms-3'>
            <Typography color={'primary'} sx={{ textAlign: 'start', cursor: 'pointer' }} variant='h5' className='mt-1' onClick={backToBusinessOrdersList}>Business Orders</Typography>
          </Grid>
          <Grid item alignContent={'center'}>
          <NavigateNextIcon />
        </Grid>
        <Grid item className='mb-1 mt-1 ms-3'>
          <Typography
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-1"
          >
            Create Business Order
          </Typography>
        </Grid>
      </Grid>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          padding: '2px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <div style={{ width: '76%' }}>

        <TextField
              size='small'
              key="businessStore"
              style={{ width: '30%', marginRight: '10px' }}
              label="Select Business Store"
              name="businessStore"
              select
              onChange={(e) => {
                setBusinessStoreId(e.target.value);
              }}
              value={businessStoreId}
            >
              {businessStoreData.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          {departmentList.length > 0 ? (
            <TextField
              size='small'
              style={{ width: '30%' }}
              key="department"
              label="Select Department"
              name="department"
              select
              onChange={(e) => {
                setDepartmentId(e.target.value);
              }}
              value={departmentId}
            >
              {departmentList.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
        </div>
      </Stack>
      <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />
      {cartData?.length ? <Order isFrom="Business" submit={createOrder} /> : <div style={{display: 'flex', justifyContent: 'center'}}><div>No Item to Display</div></div>}
    </div>
  );
};

export default BusinessOrder;
